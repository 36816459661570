import { createTheme} from "@mui/material";

let theme = createTheme({
    typography:{ 
        fontFamily: "DM Sans Regular",
        htmlFontSize: 16,
        fontSize: 14,
        button:{
          fontFamily: 'DM Sans Medium',
          fontWeight: 500,
          fontSize: '14px',
          textTransform: "capitalize",
          lineHeight: 1.75
        }
    },
    palette: {
      secondary: {
        main: '#DF6437',
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: true, // Disables the ripple effect on all buttons globally
        },
        styleOverrides: {
          contained: { // Styles applied only to contained buttons
            fontFamily: 'DM Sans Medium',
            fontWeight: 500,
            fontSize: '14px',
            textTransform: 'capitalize',
            lineHeight: 1.75,
            '&:hover': { // Apply hover effect only on contained buttons
              backgroundColor: '#DF6437',
            },
          },
        },
      },
    },
});

export default theme;

import React from 'react'
import {AppBar, Typography} from '@mui/material';
import { capitalizeWords } from '../../utils/stringFunctions';

function AppFooter({text,redirectLink,footerHeight}) {
    const handleRedirect=()=>{
        window.open(redirectLink, '_blank');
    }
    return (
        <AppBar
            position="fixed"
            contained
            sx={{
                background:'#FFFFFF',
                zIndex:'1300',
                color:'#030229',
                top: 'auto', 
                bottom: 0,
                display: 'flex',
                minHeight:`${footerHeight}px`,
                maxHeight:`${footerHeight}px`,
                justifyContent:"center",
                alignItems:'center',
                boxShadow:'none'
            }}
        > 
           <Typography sx={{fontFamily:'DM Sans Medium',fontSize:'12px',cursor:'pointer'}} onClick={handleRedirect}>{capitalizeWords(text)}</Typography>
        </AppBar>
    )
}

export default AppFooter
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { TailSpin } from 'react-loader-spinner';
import { Box } from '@mui/material';
import AppExtensionsSDK from '@pipedrive/app-extensions-sdk';
import { SERVER_BASE_URL } from '../../constants.js';

function AuthRedirect() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const appPlatform = searchParams.get('platform');
  const signIn = useSignIn();
  const [receivedToken, setReceivedToken] = useState(searchParams.get('token'));

  const initializeSdk = async () => {
    const sdk = await new AppExtensionsSDK({
      identifier: searchParams.get('id'),
    }).initialize({ size: { height: 600, width: 700 } });
  };

  const authenticate = async (exchangeToken) => {
    try {
      const response = await axios.post(`
        ${SERVER_BASE_URL}/sharedInbox/auth/authExchange`,
        {},
        { headers: { exchangeToken } }
      );

      if (response.status === 200) {
        const success = signIn({
          auth: { token: response.data?.authToken, type: 'Bearer' },
          userState: response.data?.userState,
          isSignIn: true,
          isUsingRefreshToken: false,
        });

        if (success) {
          const currentParams = new URLSearchParams(searchParams.toString());
          currentParams.set('view', 'iframe');
          navigate(`/conversation?${currentParams.toString()}`);
        }
      } else {
        navigate(`/login`);
      }
    } catch (error) {
      console.error('Error authenticating exchange token:', error);
      navigate(`/login`);
    }
  };

  useEffect(() => {
    if (appPlatform === 'pipedrive') {
      console.log('Initializing Pipedrive Instance');
      initializeSdk();
    }

    const handleMessage = (event) => {
        const allowedOrigins = [
            process.env.REACT_APP_APP_BASE_URL,
            process.env.REACT_APP_TEAMS_APP_BASE_URL
        ];
        
        if (!allowedOrigins.includes(event.origin)) return;
        
        console.log('Inside Handle Message');
        const { token } = event.data;
        if (token) {
            // console.log('Received token via postMessage:', token);
            setReceivedToken(token);
        }
    };

    window.addEventListener('message', handleMessage);

    // return () => {
    //   window.removeEventListener('message', handleMessage);
    // };
  }, [appPlatform, searchParams]);

  useEffect(() => {
    if (receivedToken) {
      authenticate(receivedToken);
    }
  }, [receivedToken]);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <TailSpin height="60" width="60" color="#FFE6CB" />
    </Box>
  );
}

export default AuthRedirect;
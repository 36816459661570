import React,{useMemo} from 'react';
import {Box, TextField, Grid, IconButton, Typography, Stack, Tooltip} from '@mui/material';
import {SendRounded,SendTimeExtensionRounded} from '@mui/icons-material';
import {useAppStore} from './AppStore';
import TemplatedMessageInitializer from '../Common/TemplatedMessageInitializer';
import MediaMessageInitializer from '../Common/MediaMessageInitializer';

function ConversationFooter({room}) {
    const roomId = room?.documentId
    const {useMessageByRoomId, changeMessageInContext,sendMessage, deleteMessageFromContext } = useAppStore();
    const message = useMessageByRoomId(roomId)
    
    const handleCustomerMessageKeyDown = (e) => {
        const value = message?.text;
        if (e.key === 'Enter' && !e.shiftKey && value !== undefined && value.trim() !== "") {
            handleSendMessage(e);
        } else if (e.key === 'Enter' && e.shiftKey) {
            changeMessageInContext(room.documentId, message?.text.concat('\n') ?? "");
        }
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        const sent = sendMessage(room.documentId, message);
        if (sent) {
            deleteMessageFromContext(room.documentId);
        }
    };

    const has24HrsPassed = useMemo(() => {
        const lastActivityDate = new Date(room.lastActivity?.timestamp);
        const currentDate = new Date();
        const diffInHours = (currentDate - lastActivityDate) / (60 * 60 * 1000);
        return diffInHours > 24;
    }, [room.lastActivity]);

    const isWhatsApp = room.channel === 'whatsapp';
    const prefilData={ customer: room?.variables?.customer, channel: room.channel, phoneNumber: room.conversationId ,type:"template" }

    return (
        <React.Fragment>
            {has24HrsPassed && isWhatsApp ? (
                <Grid container direction="row" spacing={2} sx={{ pl: 2, pr: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderRadius:'5px',p:2,background:'#FFE6CB'}}>
                            <Typography sx={{fontFamily:'DM Sans Medium',fontSize:'14px',mr:1}}>
                                Messaging window is closed . Initiate a new whatsapp room by triggering a templated message.
                            </Typography>
                            <TemplatedMessageInitializer prefilData={prefilData} buttonIcon={<SendTimeExtensionRounded/>}/>
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <form 
                onSubmit={handleSendMessage} 
                style={{ width: '100%' }}
                >
                    <Stack direction="row" sx={{pl:1,pr:1,justifyContent:'center'}} spacing={1}>
                                <Stack direction="row" spacing={1} sx={{alignSelf:'flex-start'}}>
                                    <TemplatedMessageInitializer prefilData={prefilData} buttonIcon={<SendTimeExtensionRounded/>} resetToDefault={false} />
                                    {isWhatsApp && 
                                        <MediaMessageInitializer channel={room.channel} roomId={room.documentId} conversationId={room.conversationId}/>
                                    }
                               </Stack>
                            <Box sx={{ flexGrow: 1 }}>
                                <TextField
                                    color='secondary'
                                    variant='outlined'
                                    margin='none'
                                    fullWidth
                                    sx={{ minHeight:'40px', overflowY:'hidden' }}
                                    value={message?.text ?? ""}
                                    onKeyDown={handleCustomerMessageKeyDown}
                                    onChange={(e) => changeMessageInContext(room.documentId, e.target.value)}
                                    multiline
                                    maxRows={8}
                                    size='small'
                                    type="text"
                                    placeholder='Your Message'
                                />
                            </Box>
                            <Stack direction="row" spacing={1} sx={{alignSelf:'flex-start'}}>
                                <Tooltip arrow placement="top" title="Send Message" enterDelay={1000}>
                                    <IconButton 
                                        type="submit" 
                                        color='secondary'
                                        disableRipple 
                                        sx={{
                                            minHeight: '40px',
                                            background: "#F5F5F5",
                                            borderRadius: "5px",
                                            pointerEvents: !message?.text?.trim() ? 'none' : 'auto',
                                        }}
                                        onClick={(e) => {
                                            if (message?.text?.trim()) {
                                                handleSendMessage(e);
                                            }
                                        }}
                                    >
                                        <SendRounded/>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                    </Stack>
                </form>
            )}
        </React.Fragment>
    );
}

export default ConversationFooter;

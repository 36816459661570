import Dexie from 'dexie';

const db = new Dexie('mercuri-inbox-database');
db.version(3).stores({
    rooms: 'documentId, lastActivity.timestamp, status',
    participants: 'documentId, roomId',
    events: 'documentId, roomId ,timestamp',
    notifications: 'documentId, roomId, timestamp',
    // context:'roomId ,contextType,[roomId+contextType]',
    context:'[roomId+contextType]',
    agents: 'userId',
    contactLabels: 'labelId',
    contactInfo: 'contactId',
    dbStats:'id',
});

export default db
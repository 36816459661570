import React, { createContext, useContext,useEffect, useState } from 'react';
import { setAuthToken } from '../../auth/axiosConfig.js';
import db from "../../services/indexedDB.js";
import { useLiveQuery } from 'dexie-react-hooks';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

import { 
    getAgents as getAgentsFromServer, 
    getRooms as getRoomsFromServer,
    getEvents as getEventsFromServer,
    getNotifications as getNotificationsFromServer,
    deleteNotifications as deleteNotificationsInServer,
    getParticipants as getParticipantsFromServer,
    updateConversationStatus  as updateConversationStatusInServer,
    getContactLabels as getContactLabelsFromServer,
    getContactInfo as getContactInfoFromServer,
    updateLabelsOfContact as updateLabelsOfContactInServer,
    updateRoomAgentsAssignment,
    fetchAvailableChannels
} 
from '../../services/conversations.js';
import socket from '../../socket/index.js';

const AppContext = createContext();

export function AppContextProvider({ children }) {

    const authUser = useAuthUser();
    
    const user = authUser?.user ?? null;
    const instanceId = authUser?.instanceId ?? null;

    const authHeader = useAuthHeader();

    const [loadingContext,setLoadingContext]=useState(false)

    const rooms = useLiveQuery(() => db.rooms.orderBy('lastActivity.timestamp').reverse().toArray(), []) ?? [];
    const participants = useLiveQuery(() => db.participants.toArray(), [])??[];
    const events = useLiveQuery(() => db.events.orderBy('timestamp').reverse().toArray(), [])??[];
    const notifications = useLiveQuery(() => db.notifications.toArray(), [])??[];
    const agents = useLiveQuery(() => db.agents.toArray(), [])??[];
    const contactLabels = useLiveQuery(() => db.contactLabels.toArray(), [])??[];

    const useRoomById = (roomId) => {
        return useLiveQuery(() => db.rooms.get(roomId),[roomId])
    };

    const useMessageByRoomId = (roomId) => {
        return useLiveQuery(() => 
          db.context
            .where({ roomId, contextType: 'message' }) 
            .first()
        , [roomId]) ?? {}; 
    };

    const changeMessageInContext = async (roomId, value) => {
        const newMessage = { type: 'text', text: value };
        await db.context.put({ roomId, contextType: 'message', ...newMessage });
    };

    const deleteMessageFromContext = async (roomId) => {
        await db.context
          .where({ roomId, contextType: 'message' })
          .delete();
    };

    // const upsertRoom = async (room) => {
    //     await db.rooms.put(room);
    // };

    const upsertRoom = async (room) => {
        const existingRoom = await db.rooms.get(room.documentId); 
        await db.rooms.put(room);
        return existingRoom ? true : false
    };

    const updateRooms = async (newRooms) => {
        await db.rooms.bulkPut(newRooms);
    };

    const useRoomsCount = (docRef) => {
        return useLiveQuery(async () => {
            const data = await db.dbStats.get('roomsCount')
            return data?.count;
        }, [docRef]) || rooms?.length ; 
    };

    const updateRoomsCount = async (count) => {
        await db.dbStats.put({ id:'roomsCount', count: count });
    };

    const increaseRoomsCount = async () => {
        const currentStats = await db.dbStats.get('roomsCount');
        const currentCount = currentStats ? currentStats.count : 0;
        const newCount = currentCount + 1;
        await updateRoomsCount(newCount);
        return newCount; 
    };

    

    const useChannels = (cbInstance) => {
        return useLiveQuery(async () => {
            const data = await db.dbStats
              .where('id')
              .equals("communicationChannels")
              .first()
            return data?.channels || {"sms":false,"whatsapp":false}
        }, [cbInstance]) || {"sms":false,"whatsapp":false}
    };

    const updateChannels = async (data) => {
        await db.dbStats.put({ id:'communicationChannels', channels:data});
    };

    const useEventsByRoomId = (roomId) => {
        return useLiveQuery(async () => {
          const events = await db.events
            .where('roomId')
            .equals(roomId)
            .sortBy('timestamp');
          return Array.isArray(events) ? events : [];
        }, [roomId]) || [];
    };
      
    const useEventsCountByRoomId = (roomId) => {
        return useLiveQuery(async () => {
            const data= await db.context
                .where({ roomId, contextType: 'eventsCount' }) 
                .first()
            return data?.count
        }, [roomId]) || 0; 
    };

    const useUpdatedLabelsByRoomId = (roomId) => {
        return useLiveQuery(async () => {
            const data= await db.context
                .where({ roomId, contextType: 'customerLabels' }) 
                .first()
            return data?.updatedLabels || []
        }, [roomId]) ||[]; 
    };

    const getUpdatedLabels = async(roomId)=>{
        try{
            const data= await db.context
            .where({ roomId, contextType: 'customerLabels' }) 
            .first()
            return data?.updatedLabels || []
        }
        catch(e){
            return []
        }
    }
    const updateLabelsInContext = async(roomId, labels) =>{
        const validLabels = labels.filter(label => label !== undefined && label !== null);
        await db.context.put({ roomId, contextType: 'customerLabels',updatedLabels:labels });
    }

    const updateEventsCountInContext = async (roomId, count) => {
        await db.context.put({ roomId, contextType: 'eventsCount',count:count });
    };
    
    const increaseEventsCountInContext = async (roomId) => {
        const currentStats = await db.context.where({ roomId, contextType: 'eventsCount' }).first();
        const currentCount = currentStats && currentStats.contextType === 'eventsCount' ? currentStats.count : 0;
        const newCount = currentCount + 1;
        await updateEventsCountInContext(roomId, newCount);
        return newCount;
    };
    
    
    
    
    const useActualAgentsByRoomId = (roomId) => {
        return useLiveQuery(async () => {
            const query = await db.context
                .where({ roomId, contextType: 'agents' })
                .first();
            return query?.actualAgents || [];
        }, [roomId]) || [];
    };
    const setActualAgentsInContext = async (roomId, value) => {
        try {
            const existingRecord = await db.context
                .where('[roomId+contextType]')
                .equals([roomId, 'agents'])
                .first();
            if (existingRecord) {
                await db.context
                    .where('[roomId+contextType]')
                    .equals([roomId, 'agents'])
                    .modify({ actualAgents: value });
            } else {
                await db.context.put({ roomId, contextType: 'agents', actualAgents: value ,updatedAgents:value });
            }
        } 
        catch (e) {
            console.error("Error performing upsert operation for actualAgents:", e);
        }
    };
    
    const useUpdatedAgentsByRoomId = (roomId) => {
        return useLiveQuery(async () => {
            const query = await db.context
                .where({ roomId, contextType: 'agents' })
                .first();
            return query?.updatedAgents || [];
        }, [roomId]) || [];
    };
    const setUpdatedAgentsInContext = async (roomId, value) => {
        try {
            await db.context
                .where('[roomId+contextType]')
                .equals([roomId, 'agents'])
                .modify({ updatedAgents: value });
        } catch (e) {
            console.error("Error performing upsert operation:", e);
        }
    };
    

    const updateRoomAgents = async (roomId, data) => {
        try{
            const conversation=await db.rooms.get(roomId)
            const response = await updateRoomAgentsAssignment({
                conversation: conversation,
                agents: data,
            });
            return response.status === 200 ? true : false;
        }
        catch(e){
            console.log(e)
            return false
        }
    };



    const getEventsOfRoom = async (roomId) => {
        return await db.events.where('roomId').equals(roomId).toArray();
    };
    
    const updateEvents = async (roomId, newEvents) => {
        const existingEvents = await db.events.where('roomId').equals(roomId).toArray();
        const existingEventIds = new Set(existingEvents.map(event => event.documentId));
        const filteredNewEvents = newEvents.filter(event => !existingEventIds.has(event.documentId));
        const mergedEvents = [...filteredNewEvents, ...existingEvents].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        const response=await db.events.bulkPut(mergedEvents.map(event => ({ roomId, ...event })));
    }; //might not be needed as pk exists in db , it can handle by itself

    // const upsertEvent = async (roomId, newEvent) => {
    //     const response=await db.events.put({ roomId, ...newEvent });
    // };


    const upsertEvent = async (roomId, newEvent) => {
        if (!newEvent || !newEvent.documentId) {
            throw new Error("Invalid event object or missing documentId");
        }
        const existingEvent = await db.events.get(newEvent.documentId);
        await db.events.put({ roomId, ...newEvent });
        return existingEvent ? true : false;
    };


    const updateNotifications = async (newNotifications) => {
        await db.notifications.bulkPut(newNotifications);
    };
    
    const addNotification =async(notification)=>{
        await db.notifications.put(notification);
    }
    const deleteNotificationByRoomId = async (roomId) => {
        try {
            const response = await deleteNotificationsInServer(roomId);
            if (response.status === 200) {
                const deletedCount = await db.notifications
                    .where("roomId")
                    .equals(roomId)
                    .delete();
                return deletedCount;
            } else {
                console.error(`Failed to delete notifications on server: ${response.status}`);
                return 0;
            }
        } catch (error) {
            console.error(`Error deleting notifications for roomId: ${roomId}`, error);
            return 0;
        }
    };
    
    
    const useNotificationsCountByRoomId = (roomId) => {
        return useLiveQuery(async () => {
            const events = await db.notifications
              .where('roomId')
              .equals(roomId)
              .count()
            return events;
        }, [roomId])??0
    };


    const updateAgents = async (newAgents) => {
        await db.agents.bulkPut(newAgents);
    };

    const updateContactLabels = async (newContact) => {
        await db.contactLabels.bulkPut(newContact);
    };

    const fetchContactLabels=async()=>{
        const response = await getContactLabelsFromServer()
        if(response.status===200){
            updateContactLabels(response.data??[])
        }
    }

    const useLabelsByContactId = (contactId) => {
        try{
            const labels = useLiveQuery(async () => {
            if (![undefined,null].includes(contactId)) {
                const contact = await db.contactInfo
                .get(contactId)
                return contact?.labels ?? [];
            }
                return []; 
            }, [contactId]) ?? [];
            return labels;
        }
        catch(e){
            console.log(e)
            return []
        }
    };

    const syncLabelsOfContact = async (roomId, contactId) => {
        try {
            const contact = await db.contactInfo.get(contactId);
            const existingLabels = contact?.labels ?? [];
            const updatedLabels = await getUpdatedLabels(roomId);
            const labelsToDelete = existingLabels.filter(label => !updatedLabels.includes(label)).filter(label => label !== undefined && label !== null);
            const labelsToAdd = updatedLabels.filter(label => !existingLabels.includes(label)).filter(label => label !== undefined && label !== null);
            let serverUpdateSuccessful = true;
            if (labelsToDelete.length > 0) {
                const deleteResponse = await updateLabelsOfContactInServer({
                    contactId: contactId,
                    labels: labelsToDelete,
                    action: 'delete',
                });
    
                if (deleteResponse.status !== 200) {
                    console.error('Failed to delete some labels on the server:', deleteResponse);
                    serverUpdateSuccessful = false;
                }
            }
            if (labelsToAdd.length > 0) {
                const addResponse = await updateLabelsOfContactInServer({
                    contactId: contactId,
                    labels: labelsToAdd,
                    action: 'update',
                });
    
                if (addResponse.status !== 200) {
                    console.error('Failed to add some labels on the server:', addResponse);
                    serverUpdateSuccessful = false;
                }
            }
            if (serverUpdateSuccessful) {
                const mergedLabels = Array.from(new Set(updatedLabels));
                await db.contactInfo.update(contactId, { labels: mergedLabels });
                await updateLabelsInContext(roomId, mergedLabels);
                return true;
            } else {
                console.error('Label synchronization failed on the server.');
                return false;
            }
        } catch (e) {
            console.error('Error while synchronizing labels:', e);
            return false;
        }
    };
    
    const clearIndexedDB = async () => {
        try {
            await db.transaction('rw', db.tables, async () => {
                for (const table of db.tables) {
                    await table.clear();
                }
            });
            console.log("All tables cleared successfully.");
        } 
        catch (error) {
            console.error("Failed to clear data from IndexedDB:", error);
        }
    };
    
    async function fetchInitialData() {
        setLoadingContext(true)
        try {
            const [roomsResponse, agentsResponse,notificationsResponse,contactLabelsResponse,channelsResponse] = await Promise.all([
                getRoomsFromServer(),
                getAgentsFromServer(),
                getNotificationsFromServer(),
                getContactLabelsFromServer(),
                fetchAvailableChannels()
            ]);
            if (roomsResponse.status === 200 && agentsResponse.status === 200) {
                if(roomsResponse.data.rooms.length>0){
                    updateRooms(roomsResponse.data.rooms||[])
                    updateRoomsCount(roomsResponse.data.count||roomsResponse.data.rooms?.length)
                }
                updateAgents(agentsResponse.data || []);
            }
            if(notificationsResponse.status===200){
                updateNotifications(notificationsResponse.data??[])
            }
            if(contactLabelsResponse.status===200){
                updateContactLabels(contactLabelsResponse.data??[])
            }
            if(channelsResponse.status===200){
                if(channelsResponse.data){
                    updateChannels(channelsResponse.data)
                }
            }
        } 
        catch (error) {
            console.error("Error fetching initial data:", error);
        } 
        finally{
            setLoadingContext(false)
        }
    }

    const fetchEvents = async (roomId,startAfterDocument=null) => {
        try {
            const response=await getEventsFromServer(roomId, startAfterDocument);
            if(response.status===200){
              if(response.data?.events?.length>0){
                updateEvents(roomId,response.data.events)
              }
              if(response.data?.count){
                updateEventsCountInContext(roomId,response.data?.count)
              }
            }
        } catch (error) {
          console.error('Error fetching rooms data:', error);
        }
    };
    
    const fetchParticipants = async (roomId) => {
        try {
            const response = await getParticipantsFromServer(roomId);
            if(response.status===200){
                if(response.data?.participants?.length>0){
                    await db.participants.bulkPut(response.data?.participants)
                    const values = agents.filter((agent) =>response.data?.participants?.some((participant) => participant.userId === agent.userId)).map((agent) => agent.userId);
                    setActualAgentsInContext(roomId, values);
                }
            }
        } catch (error) {
            console.error('Error fetching rooms data:', error);
        }
    };

    const getContactInfo = async (roomId,contactId) => {
        try {
            const response = await getContactInfoFromServer(contactId);
            if(response.status===200){
                if(response.data){
                    await db.contactInfo.put(response.data);
                    const fetchedLabels = response.data?.labels??[]
                    await updateLabelsInContext(roomId,fetchedLabels);
                }
            }
        } catch (error) {
            console.error('Error fetching rooms data:', error);
        }
    };

    const changeRoomStatus = async (conversation, status) => {
        if (conversation !== null && status !== null) {
            const response = await updateConversationStatusInServer(conversation, status);
            if (response.status === 200) {
                await db.rooms.update(conversation.documentId, {
                    status: status,
                });
            }
        }
    };


    const sendMessage = (roomId,message,templateMessage={}) => {
        var data={}
        const conversation = rooms.filter(
          (conv) => conv.documentId === roomId
        )[0];
        if (conversation) {
            data = {
                instanceId: instanceId,
                conversation: {
                    channel: conversation.channel,
                    conversationId: conversation.conversationId,
                    roomId: conversation.documentId,
                },
                participant: user,
                event: message,
            };
        }
        else if(Object.keys(templateMessage).length>0){
            data = {
                instanceId: instanceId,
                conversation: {
                    channel: templateMessage?.channel,
                    conversationId: templateMessage.conversationId,
                    roomId: templateMessage.roomId,
                },
                customer:templateMessage.customer,
                participant: user,
                event: message,
            };
        }
        if(Object.keys(data).length>0){
          socket.emit("conversation.agent.message", data);
        }
        return true;
    };



    useEffect(() => {
        if (authHeader) {
            setAuthToken(authHeader);
            fetchInitialData();
        } 
        else {
            clearIndexedDB();
        }
    }, [authHeader]); 


    const contextValue = {
        loadingContext:loadingContext,
        
        rooms:rooms,
        useRoomById:useRoomById,
        upsertRoom:upsertRoom,
        updateRooms:updateRooms,
        useRoomsCount:useRoomsCount,
        updateRoomsCount:updateRoomsCount,
        increaseRoomsCount:increaseRoomsCount,
        

        agents:agents,
        updateAgents:updateAgents,
        agentsCount:agents?.length??0,

        participants:participants,
        fetchParticipants:fetchParticipants,
        useActualAgentsByRoomId:useActualAgentsByRoomId,
        useUpdatedAgentsByRoomId:useUpdatedAgentsByRoomId,
        setActualAgentsInContext:setActualAgentsInContext,
        setUpdatedAgentsInContext:setUpdatedAgentsInContext,
        updateRoomAgents:updateRoomAgents,

        useMessageByRoomId:useMessageByRoomId,
        changeMessageInContext:changeMessageInContext,
        deleteMessageFromContext:deleteMessageFromContext,
      

        events:events,
        eventsCount:events?.length??0,
        getEvents:getEventsOfRoom,
        updateEvents:updateEvents,
        upsertEvent:upsertEvent,
        useEventsByRoomId:useEventsByRoomId,
        useEventsCountByRoomId:useEventsCountByRoomId,
        updateEventsCountInContext:updateEventsCountInContext,
        increaseEventsCountInContext:increaseEventsCountInContext,

        
        notifications:notifications,
        notificationsCount:notifications?.length??0,
        addNotification:addNotification,
        deleteNotificationByRoomId:deleteNotificationByRoomId,
        updateNotifications:updateNotifications,
        useNotificationsCountByRoomId:useNotificationsCountByRoomId,

        contactLabels:contactLabels,
        getContactInfo:getContactInfo,
        useLabelsByContactId:useLabelsByContactId,
        useUpdatedLabelsByRoomId:useUpdatedLabelsByRoomId,
        updateLabelsInContext:updateLabelsInContext,
        syncLabelsOfContact:syncLabelsOfContact,
        fetchContactLabels:fetchContactLabels,

        clearIndexedDB:clearIndexedDB,
        changeRoomStatus:changeRoomStatus,
        fetchEvents :fetchEvents,
        useChannels:useChannels,
        fetchParticipants:fetchParticipants,
        sendMessage:sendMessage,

    };

    
    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
}

export function useAppStore() {
    return useContext(AppContext);
}

import React, { useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const mapContainerStyle = {
    width: '100%',
    height: '100%',
};

function GoogleMapView({center}) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_JS_API_KEY
    });

    const mapRef = useRef(null);

    const onUnmount = React.useCallback((map) => {
        mapRef.current = null;
    }, []);

    return (
        <React.Fragment>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={15}
                    onUnmount={onUnmount}
                >
                    <Marker position={center}/> 
                </GoogleMap>
            ) : (
                <Typography>Loading map...</Typography>
            )}
        </React.Fragment>
    );
}

export default GoogleMapView;
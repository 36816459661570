import React,{useEffect, useState} from 'react';
import {Select,Menu,MenuItem,SwipeableDrawer,InputAdornment,Grid, Box,Typography,Stack, IconButton, Button, Avatar, ListItemIcon, ListItemText, OutlinedInput, Checkbox, ListItem, ListItemButton, Tooltip} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import { useAppStore} from './AppStore.jsx';
import { hasArrayChanged,checkArrayItemsPresence} from '../../utils/arrayFunctions.js';
import { capitalizeWords, stringAvatar,uuidToBase64 } from '../../utils/stringFunctions.js';
import CircleIcon from '@mui/icons-material/Circle';
import PublishedWithChangesRoundedIcon from '@mui/icons-material/PublishedWithChangesRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";


function ConversationHeader({
  selectedConversation,
  setSelectedConversation,
  handleInfoToggle,
  room,
}) {
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const statuses = [
    { value: "active", color: "green" },
    { value: "closed", color: "red" },
  ];

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const {changeRoomStatus,agents,participants,fetchParticipants,useActualAgentsByRoomId,useUpdatedAgentsByRoomId,setActualAgentsInContext,setUpdatedAgentsInContext,updateRoomAgents} = useAppStore();
  const [showAssign, setShowAssign] = useState(false);

  const actualAgents=useActualAgentsByRoomId(selectedConversation)
  const updatedAgents=useUpdatedAgentsByRoomId(selectedConversation)

  const handleAgentSelectChange = (value) => {
    setUpdatedAgentsInContext(room.documentId, value);
  };

  async function updateAgentRoomAssignment() {
    if (
      hasArrayChanged(
        actualAgents,
        updatedAgents
      )
    ) {
      const finalAgents = checkArrayItemsPresence(
        actualAgents,
        updatedAgents
      );
      const response = await updateRoomAgents(
        room.documentId,
        finalAgents 
      );
      if (response === true) {
        setActualAgentsInContext(
          room.documentId,
          updatedAgents
        );
        setShowAssign(false);
      } else {
        setUpdatedAgentsInContext(
          room.documentId,
          actualAgents
        );
        setShowAssign(false);
      }
    }
  }

  useEffect(()=>{
    fetchParticipants(selectedConversation)
  },[selectedConversation])

  useEffect(()=>{
    setShowAssign(
      hasArrayChanged(actualAgents, updatedAgents)
    );
  },[actualAgents,updatedAgents])


  return (
    isMobile ? 
    <Grid
      container
      direction="row"
      spacing={2}
      sx={{ p: 2, borderBottom: "1px solid #F2F2F2" }}
    >
    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => {
              setSelectedConversation(null);
            }}
            size="small"
            disableFocusRipple
            disableRipple
          >
            <KeyboardBackspaceOutlinedIcon sx={{ fontSize: "24px", mr: 1 }} />
          </IconButton>
          <Avatar
            sx={{ height: "60px", width: "60px" }}
            {...stringAvatar(
              (room.variables &&
              room.variables.customer &&
              room.variables.customer.firstName
                ? room.variables.customer.firstName + " "
                : "") +
                (room.variables &&
                room.variables.customer &&
                room.variables.customer.lastName
                  ? room.variables.customer.lastName
                  : "") || "Customer"
            )}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
              alignItems: "left",
              pl: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "DM Sans Medium",
                fontSize: 14,
                fontWeight: 500,
                textOverflow: "ellipsis",
              }}
            >
              {(() => {
                const firstName =
                  room.variables?.customer?.firstName || "";
                const lastName =
                  room.variables?.customer?.lastName || "";
                const customerName = `${firstName} ${lastName}`.trim();
                const profileName=room.variables?.customer?.profileName || "";
                if (customerName||profileName) {
                  return customerName||profileName;
                } 

                if (room.channel.toLowerCase() === "widget") {
                  return `${room.channel.toUpperCase()} ${uuidToBase64(
                    room.conversationId
                  )}`;
                } else {
                  return room.conversationId;
                }
              })()}
            </Typography>
          </Box>
        </Box>
        <Box>
          <IconButton onClick={handleDrawerToggle} sx={{ color: "#000000" }}>
            <PermIdentityOutlinedIcon />
          </IconButton>
          <IconButton onClick={handleMenuClick} sx={{ color: "#000000" }}>
            <MoreVertOutlinedIcon />
          </IconButton>
        </Box>
      </Box>

      
      <SwipeableDrawer
        anchor="bottom"
        open={openDrawer}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            m:1,
            position: "absolute",
            minHeight: "30%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            overflowY: "auto",
            borderRadius: "30px 30px 0px 0px",
          },
        }}
      >
    
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Select
            color='secondary'
            multiple
            fullWidth
            size="small"
            value={updatedAgents ?? []}
            sx={{
              borderRadius: 25,
            }}
            startAdornment={
              <InputAdornment position="start">
                <AccountCircleIcon
                  sx={{
                    color: "#DF6437",
                    fontSize: 30,
                  }}
                />
              </InputAdornment>
            }
            onChange={(e) => {
              handleAgentSelectChange(e.target.value);
            }}
            renderValue={(selected) => {
              const displayLimit = 2;
              // const selectedNames = selected.map((userId) => {
              //   const agent = agents.find((agent) => agent.userId === userId);
              //   return agent
              //     ? `${agent.accountInfo.firstName} ${agent.accountInfo.lastName}`
              //     : "";
              // });
              const selectedNames = selected.map((userId) => {
                const agent = agents.find((agent) => agent.userId === userId);
                if (agent) {
                    const { firstName, lastName, userName } = agent.accountInfo || {};
                    // Use firstName and lastName if available, otherwise fallback to userName
                    return (firstName || lastName) 
                        ? `${firstName || ''} ${lastName || ''}`.trim() 
                        : userName || ""; 
                }
                return "";
              })
              return (
                <span>
                  {selectedNames.slice(0, displayLimit).join(", ")}
                  {selectedNames.length > displayLimit
                    ? ` +${selectedNames.length - displayLimit} more`
                    : ""}
                </span>
              );
            }}
          >
            {agents.map((agent) => (
              <MenuItem color='secondary' value={agent.userId} key={agent.userId} sx={{p:0}}>
                <Checkbox
                  size='small'
                  color='secondary'
                  checked={updatedAgents.includes(agent.userId)}
                />
                {/* <ListItemText primary={`${agent.accountInfo.firstName} ${agent.accountInfo.lastName}`}/> */}
                <ListItemText
                    primary={
                        (agent.accountInfo.firstName || agent.accountInfo.lastName)
                            ? `${agent.accountInfo.firstName || ''} ${agent.accountInfo.lastName || ''}`.trim()
                            : agent.accountInfo.userName || "Internal User"
                    }
                />
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Box sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            sx={{
              mb: 1,
              color: "#FFFF",
              border: "1px solid #E9963F",
            }}
            onClick={updateAgentRoomAssignment}
          >
            Assign
          </Button>
        </Box>
      </SwipeableDrawer>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          "& .MuiPaper-root": {
            width: "200px",
          },
        }}
      >
        <Select
          color='secondary'
          size="small"
          sx={{
            minWidth: isSmallScreen ? "90%" : 150,
            maxWidth: isSmallScreen ? "90%" : 150,
            borderRadius: 25,
            overflow: "hidden",
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                background: "#FFFFFF",
              },
            },
          }}
          value={room.status}
          renderValue={(selected) => {
            const status = statuses.find(
              (status) => status.value === selected
            );
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <CircleIcon style={{ color: status.color, fontSize: 20 }} />
                <span style={{ marginLeft: 8 }}>
                  {capitalizeWords(status.value)}
                </span>
              </div>
            );
          }}
          onChange={(e) => {
            changeRoomStatus(room, e.target.value);
          }}
        >
          {statuses.map((status,index) => (
            <MenuItem color='secondary' value={status.value} key={index}>
              <ListItemIcon >
                <CircleIcon style={{ color: status.color, fontSize: 20 }} />
              </ListItemIcon>
              <ListItemText primary={capitalizeWords(status.value)} />
            </MenuItem>
          ))}
        </Select>
      </Menu>
    </Grid>
  </Grid> :

    <Grid
      container
      direction="row"
      spacing={2}
      sx={{ p: 2, borderBottom: "1px solid #F2F2F2" }}
    >
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                setSelectedConversation(null);
              }}
              size="small"
              disableFocusRipple
              disableRipple
            >
              <ArrowBackIosRoundedIcon sx={{ fontSize: "12px", mr: 1 }} />
            </IconButton>
            <Avatar
              {...stringAvatar(
                (room.variables &&
                room.variables.customer &&
                room.variables.customer.firstName
                  ? room.variables.customer.firstName + " "
                  : "") +
                  (room.variables &&
                  room.variables.customer &&
                  room.variables.customer.lastName
                    ? room.variables.customer.lastName
                    : "") || "Customer"
              )}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                alignItems: "left",
                pl: 2,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "DM Sans Medium",
                  fontSize: 14,
                  fontWeight: 500,
                  textOverflow: "ellipsis",
                }}
              >
                {(() => {
                  const firstName =
                    room.variables?.customer?.firstName || "";
                  const lastName =
                    room.variables?.customer?.lastName || "";
                  const customerName = `${firstName} ${lastName}`.trim();
                  const profileName=room.variables?.customer?.profileName || "";
                  if (customerName||profileName) {
                    return customerName||profileName;
                  } 

                  if (room.channel.toLowerCase() === "widget") {
                    return `${room.channel.toUpperCase()} ${uuidToBase64(
                      room.conversationId
                    )}`;
                  } else {
                    return room.conversationId;
                  }
                })()}
              </Typography>
            </Box>
          </Box>
          {isSmallScreen
            ? !isMobile && (
                <Box
                  sx={{
                    ml: "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    alignItems: "center",
                  }}
                >
                  <IconButton onClick={handleInfoToggle}>
                    <MenuOpenRoundedIcon />
                  </IconButton>
                </Box>
              )
            : null}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Stack
          direction={isSmallScreen ? "column" : "row"}
          spacing={2}
          sx={{
            justifyContent: isSmallScreen ? "center" : "flex-end",
            alignItems: isSmallScreen ? "center" : "flex-end",
          }}
        >
          <Select
            color='secondary'
            multiple
            size="small"
            value={updatedAgents ?? []}
            sx={{
              minWidth: isSmallScreen ? 300 : 175,
              maxWidth: isSmallScreen ? 300 : 175,
              paddingRight: showAssign ? "20px" : "8px",
              borderRadius: 25,
            }}
            startAdornment={
              <InputAdornment position="start">
                <AccountCircleIcon
                  sx={{
                    color: "#DF6437",
                    fontSize: 30,
                  }}
                />
              </InputAdornment>
            }
            endAdornment={
              showAssign ? (
                <Tooltip title="Save">
                  <IconButton
                    onClick={updateAgentRoomAssignment}
                    color="secondary"
                  >
                    <PublishedWithChangesRoundedIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )
            }
            onChange={(e) => {
              handleAgentSelectChange(e.target.value);
            }}
            renderValue={(selected) => {
              const displayLimit = 2;
              // const selectedNames = selected.map((userId) => {
              //   const agent = agents.find((agent) => agent.userId === userId);
              //   return agent
              //     ? `${agent.accountInfo.firstName} ${agent.accountInfo.lastName}`
              //     : "";
              // });
              const selectedNames = selected.map((userId) => {
                const agent = agents.find((agent) => agent.userId === userId);
                if (agent) {
                    const { firstName, lastName, userName } = agent.accountInfo || {};
                    // Use firstName and lastName if available, otherwise fallback to userName
                    return (firstName || lastName) 
                        ? `${firstName || ''} ${lastName || ''}`.trim() 
                        : userName || ""; 
                }
                return "";
              })

              return (
                <span>
                  {selectedNames.slice(0, displayLimit).join(", ")}
                  {selectedNames.length > displayLimit
                    ? ` +${selectedNames.length - displayLimit} more`
                    : ""}
                </span>
              );
            }}
          >
            {agents.map((agent) => (
              <MenuItem value={agent.userId} key={agent.userId} sx={{p:0}}>
                <Checkbox
                  color='secondary'
                  size='small'
                  checked={updatedAgents.includes(agent.userId)}
                />
                {/* <ListItemText
                  primary={`${agent.accountInfo.firstName} ${agent.accountInfo.lastName}`}
                /> */}
                <ListItemText
                    primary={
                        (agent.accountInfo.firstName || agent.accountInfo.lastName)
                            ? `${agent.accountInfo.firstName || ''} ${agent.accountInfo.lastName || ''}`.trim()
                            : agent.accountInfo.userName || "Internal User"
                    }
                />
              </MenuItem>
            ))}
          </Select>
          {/* {showAssign?<IconButton onClick={(e)=>{updateAgentRoomAssignment(room.documentId)}} color="secondary">
                            <PublishedWithChangesRoundedIcon/>
                    </IconButton>:
                    <>
                    </>
                    } */}

          <Select
            color='secondary'
            size="small"
            sx={{
              minWidth: isSmallScreen ? 300 : 150,
              maxWidth: isSmallScreen ? 300 : 150,
              borderRadius: 25,
              overflow: "hidden",
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  background: "#FFFFFF",
                },
              },
            }}
            value={room.status}
            renderValue={(selected) => {
              const status = statuses.find(
                (status) => status.value === selected
              );
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CircleIcon style={{ color: status.color, fontSize: 20 }} />
                  <span style={{ marginLeft: 8 }}>
                    {capitalizeWords(status.value)}
                  </span>
                </div>
              );
            }}
            onChange={(e) => {
              changeRoomStatus(room, e.target.value);
            }}
          >
            {statuses.map((status) => (
              <MenuItem value={status.value} color='secondary'>
                <ListItemIcon>
                  <CircleIcon style={{ color: status.color, fontSize: 20 }} />
                </ListItemIcon>
                <ListItemText primary={capitalizeWords(status.value)} />
              </MenuItem>
            ))}
          </Select>

          {!isSmallScreen ? (
            <IconButton onClick={handleInfoToggle} sx={{ mr: 1 }}>
              <MenuOpenRoundedIcon />
            </IconButton>
          ) : null}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ConversationHeader
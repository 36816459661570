import React from 'react'
import {Box,Typography} from '@mui/material'
import { capitalizeWords } from '../../utils/stringFunctions';
import { extractFileExtension } from '../../utils/fileFunctions.js';

function ShowMessageType({event}) {
    const eventType = capitalizeWords(event.type);
    const fileExtension = event.document?.mimeType ? `( ${extractFileExtension(event.document.mimeType)} )` : '';
    return (
        <Box>
            <Typography  sx={{ fontSize: '13px',fontFamily:'DM Sans Medium'}}>
                {eventType} {fileExtension}
            </Typography>
        </Box>
    )
}

export default ShowMessageType
import { Box,Button,Grid,IconButton,Stack,Tooltip,Typography } from '@mui/material'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import React from 'react'
import GoogleMapView from './GoogleMapView';

function LocationMessage({event}) {

    // const handleLocationClick = (latitude, longitude) => {
    //     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    //     const googleMapsAppUrl = isMobile
    //         ? `geo:${latitude},${longitude}`
    //         : `https://www.google.com/maps?q=${latitude},${longitude}`

    //     window.open(googleMapsAppUrl, '_blank');
    // };

    const handleLocationClick = (latitude, longitude) => {
        const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`; // Marker support on mobile
        window.open(googleMapsUrl, '_blank');
    };


    const copyToClipboard = (latitude, longitude) => {
        const googleMapsLink = `https://www.google.com/maps?q=${latitude},${longitude}`;
        navigator.clipboard.writeText(googleMapsLink)
    };

    return (
        <Box sx={{mt:1}}>
            <Box
                sx={{
                    background:"#FFFFFF",
                    p:0.5,
                    border:'1px solid #F2F2F2',
                    borderRadius:"8px" ,
                    mb:2,
                }}
            >
                {(() => {
                    const center = {
                        lat: isNaN(parseFloat(event.location?.latitude)) ? 0 : parseFloat(event.location?.latitude),
                        lng: isNaN(parseFloat(event.location?.longitude)) ? 0 : parseFloat(event.location?.longitude)
                    };

                    return (
                        <Box
                            sx={{
                                width:'100%',
                                height:'250px',
                            }}
                        >
                            <GoogleMapView center={center} />
                        </Box>
                    );
                })()}
            </Box>
            {event.location?.name && <Typography  sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>Name&nbsp;:&nbsp;{event.location.name}</Typography>}
            {event.location?.url && <Typography  sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>Website&nbsp;:&nbsp;<a href={event.location.url} target="_blank" style={{color: 'blue', cursor: 'pointer',textDecoration:'none'}}>{event.location.url}</a></Typography>}
            {event.location?.address && <Typography  sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>Address&nbsp;:&nbsp;{event.location.address}</Typography>}
            {event.location?.latitude && event.location?.longitude && (
                <React.Fragment>
                    <Typography  sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>
                        Latitude&nbsp;:&nbsp;
                        <span
                            style={{ color: 'blue', cursor: 'pointer' }}
                            onClick={() => handleLocationClick(event.location.latitude, event.location.longitude)}
                        >
                            {event.location.latitude}
                        </span>
                        <br />
                        Longitude&nbsp;:&nbsp;
                        <span
                            style={{ color: 'blue', cursor: 'pointer'}}
                            onClick={() => handleLocationClick(event.location.latitude, event.location.longitude)}
                        >
                            {event.location.longitude}
                        </span>
                    </Typography>
                    <Grid container direction="row" sx={{ mt: 2 }} spacing={2}>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >
                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => handleLocationClick(event.location.latitude, event.location.longitude)}
                            >
                                View Location
                            </Button>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <Tooltip arrow placement="top" title="Copy" enterDelay={1000}>
                                <IconButton
                                    color="secondary"
                                    size="small"
                                    sx={{
                                        background: "#F5F5F5",
                                        borderRadius: "5px",
                                    }} 
                                    onClick={() => copyToClipboard(event.location.latitude, event.location.longitude)}
                                >
                                    <ContentCopyRoundedIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </Box>
    )
}

export default LocationMessage
import React from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';

function TabPanel(props) {
    const { children, value, index } = props;
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        sx={{
          display: value !== index ? "none" : "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          overflow: "auto",
          scrollbarWidth: "0.2em", // Firefox
          msOverflowStyle: "none", // IE and Edge
          "&::-webkit-scrollbar": {
            width: "0.2em", // Adjust as needed
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent", // Make the track invisible
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#DF6437", // Make the thumb (scrollbar) invisible
            borderRadius: "50px",
          },
        }}
      >
        {value === index && children}
      </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
export default TabPanel
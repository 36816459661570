import React from 'react'
import { ProductCard } from './ProductCard';
import { Avatar, Grid ,Box,FormControl,FormLabel,TextField,Typography,Button} from '@mui/material';
import botIcon from '../../assets/botIcon.jpg';
import ReactMarkdown from 'react-markdown';
import { formatDate } from '../../utils/dateTimeFunctions';

function ChatBotMessage({index,event,variables}) {
    const imageHref = (src) => {
        window.open(src, '_blank');
    }
    const components = {
        img: ({ node, ...props }) => (
            <></>
        ),
        ol: ({ node, ...props }) => (
            <ol style={{ fontSize: '13px', maxWidth: '100%',padding:'10px',marginTop:'10px'}}> 
            {props.children}
            </ol>
        ),
        li: ({ node, ...props }) => (
            <li sx={{ fontSize: '13px', maxWidth: '100%'}}>
               {props.children}
            </li>
        ),
        a: ({ node, ...props }) => (
            <a href={props.href} onClick={(e)=>{e.preventDefault();imageHref(props.href)}} target="_blank" rel="noopener noreferrer">
              {props.children}
            </a>
        ),
        p:({ node, ...props }) => (
            <Typography sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }} >
               {props.children}
            </Typography>
        ),
    };
    
    return (
    <>
            <Grid item key={index} xs={12} sm={12} md={12} lg={12} xl={12}
                aria-label="botMessage"
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    mt:1.5,mb:1.5
                }}
            >
                <Box sx={{display:'flex',flexDirection:'column',mr:1.5,ml:1}}>
                    <Avatar src={botIcon} sx={{ borderRadius: '50%', height: '30px', width: '30px'}} />
                </Box>
                <Box
                    sx={{
                       
                        width: 'fit-content',
                        maxWidth: '100%',
                        overflowWrap: 'break-word',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{display:'inline-flex',justifyContent:'flex-end',alignItems:'center',gap:1}}>
                        <Typography 
                            sx={{
                                fontFamily:'DM Sans Medium',
                                fontSize:'15px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            Bot
                        </Typography>
                        <Typography 
                            sx={{
                                fontFamily:'DM Sans Light',
                                fontSize:'12px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                            >
                            {formatDate(event.timestamp)}
                        </Typography>
                    </Box>
                    
                    <Box sx={{mt:0.5, maxWidth: '65%'}}>
                         <ReactMarkdown components={components}>{event.message}</ReactMarkdown>
                    </Box>
                    {event.extras && event.extras.type === "product" && (
                        <Grid columns={16} container direction="row" spacing={3} sx={{ maxWidth: '65%',mt:2,mb:2}}>
                            {event.extras.data.map((product, index) => (
                                <Grid item xs={16} sm={16} md={8} lg={4} xl={4} key={index}>
                                    <ProductCard data={product} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {event.extras && event.extras.type === "ticket" && (
                        <Box
                            sx={{
                                mt:2,
                                p:2,
                                borderRadius: '5px',
                                background: '#FFFFFF',
                                width: '100%',
                                border:`1px solid #A3AED0`
                            }}
                        >
                          
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography sx={{fontFamily:'DM Sans Medium'}}>Customer Details</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <FormControl fullWidth>
                                            <FormLabel sx={{mb:1}}>First Name</FormLabel>
                                            <TextField required type="text" placeholder='Enter Your First Name here' disabled value={variables?.customer?.firstName??""} size='small'/>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <FormControl fullWidth>
                                            <FormLabel sx={{mb:1}}>Last Name</FormLabel>
                                            <TextField required type="text" placeholder='Enter Your Last Name here' disabled value={variables?.customer?.lastName??""} size='small'/>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl fullWidth>
                                            <FormLabel sx={{mb:1}}>Email ID</FormLabel>
                                            <TextField required type="email" placeholder='Enter Your Email ID here' disabled value={variables?.customer?.email??""} size='small'/>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography sx={{fontFamily:'DM Sans Medium'}}>Ticket</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormControl fullWidth>
                                            <FormLabel sx={{mb:1}}>Message</FormLabel>
                                            <TextField required multiline minRows={4} placeholder='Enter your query or message here' disabled value={variables?.ticket?.message??""} size='small'/>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {variables?.ticket?.status?? "needInfo"==='submitted'?
                                        (
                                            <Typography sx={{fontSize:'13px'}} color="success">Ticket Has been Created</Typography>
                                        )
                                        :
                                        (
                                            <FormControl>
                                                <Button type='submit' variant='outlined' sx={{textTransform:'none',letterSpacing:2}} disabled >Save</Button>
                                            </FormControl>
                                        )
                                    }
                                    </Grid>
                                </Grid>
                        </Box>
                    )}
                </Box>
            </Grid>
    </>
  )
}

export default ChatBotMessage
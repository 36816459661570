import React, { useEffect, useState } from 'react';
import {
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    Grid,
    Paper,
    Box,
    CircularProgress,
    Stack,
    Snackbar,
    Alert
} from '@mui/material';
import {
    ImageRounded,
    VideocamRounded,
    ArticleRounded,
    AudiotrackRounded,
    AttachFileRounded,
    DeleteRounded,
    DoneRounded, 
    ErrorRounded, 
    SendRounded,
    CloseRounded,
    CloudUploadRounded,
    CloudDoneRounded
} from '@mui/icons-material';
import { uploadMedia } from '../../services/conversations';
import { useAppStore } from '../Main/AppStore';

const menuItems = [
    {
        id: "image",
        description: "Image",
        icon: <ImageRounded />,
        accept: "image/jpeg,image/png",
        acceptableFileNames: "jpg, jpeg, png",
        maxSize: "5 MB",
    },
    {
        id: "video",
        description: "Video",
        icon: <VideocamRounded />,
        accept: "video/mp4,video/3gp",
        acceptableFileNames: "mp4, 3gp",
        maxSize: "16 MB",
    },
    {
        id: "document",
        description: "Document",
        icon: <ArticleRounded />,
        accept: "text/plain,application/pdf,application/msword,application/vnd.ms-powerpoint,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation",
        acceptableFileNames: "txt, pdf, doc, ppt, xls, xlsx, docx, pptx",
        maxSize: "100 MB",
    },
    {
        id: "audio",
        description: "Audio",
        icon: <AudiotrackRounded />,
        accept: "audio/mpeg,audio/mp4,audio/aac,audio/amr,audio/ogg",
        acceptableFileNames: "mp3, mp4, aac, amr, ogg",
        maxSize: "16 MB",
    }
];



const FileStatusIcon = ({ status,value }) => {
    switch (status) {
      case 'pending':
        return <CloudUploadRounded/>;
      case 'uploading':
        return  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress variant="determinate" value={value} color='secondary' size={40}/>
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            component="div"
                            sx={{fontSize:12}}
                        >
                        {`${Math.round(value)}%`}
                        </Typography>
                    </Box>
                </Box>;
      case 'uploaded':
        return <CloudDoneRounded/>;
      case 'sent':
        return <DoneRounded color="primary" />;
      case 'failed':
        return <ErrorRounded color="error" />;
      default:
        return null;
    }
  };

function MediaMessageInitializer({ channel,roomId,conversationId,buttonIcon}) {
    const {sendMessage} = useAppStore();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mediaType, setMediaType] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [uploadingMedia,setUploadingMedia]=useState(false)

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleAttachmentClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDialogClose = () => {
        setFileData([])
        setUploadingMedia(false)
        setDialogOpen(false)
    };

    const handleMediaTypeSelected = (selectedType) => {
        setMediaType(selectedType);
        handleClose();
        document.getElementById(`file-input-${selectedType}`).click();
    };

    // const handleFileChange = (event) => {
    //     setFileData([]) 
    //     const files=event.target.files
    //     if (files.length > 0) {
    //         Array.from(files).forEach(file => {
    //             const reader = new FileReader();
    //             reader.onload = (e) => {
    //                 const newFileData = {
    //                     mediaType:mediaType,
    //                     fileName: file.name,
    //                     mimeType: file.type,
    //                     size: file.size,
    //                     file:file,
    //                     status:"pending",
    //                     response:{},
    //                 };
    //                 setFileData((prevData) => [...prevData, newFileData]);
    //             };
    //             reader.readAsDataURL(file);
    //         });
    //         setDialogOpen(true);
    //     }
    // };

    const [errorMessages, setErrorMessages] = useState([]);

    const handleFileChange = (event) => {
        setFileData([]); 
        const files = event.target.files;
        const selectedMenuItem = menuItems.find(item => item.id === mediaType); // Get the current media type settings
        const maxFileSizeInBytes = parseInt(selectedMenuItem.maxSize) * 1024 * 1024; // Convert maxSize to bytes
    
        if (files.length > 0) {
            let hasErrors=false;
            Array.from(files).forEach(file => {
                if (file.size > maxFileSizeInBytes) {
                    setErrorMessages(prevErrors => [...prevErrors, `File ${file.name} exceeds the max size of ${selectedMenuItem.maxSize}.`]);
                    hasErrors=true
                }
                if (!selectedMenuItem.accept.split(',').includes(file.type)) {
                    setErrorMessages(prevErrors => [...prevErrors, `File ${file.name} is not an accepted file type.\nAllowed types: ${selectedMenuItem.acceptableFileNames}`]);
                    hasErrors=true
                }
                if(hasErrors) return 

                const reader = new FileReader();
                reader.onload = (e) => {
                    const newFileData = {
                        mediaType: mediaType,
                        fileName: file.name,
                        mimeType: file.type,
                        size: file.size,
                        file: file,
                        status: "pending",
                        response: {},
                    };
                    setFileData((prevData) => [...prevData, newFileData]);
                };
                reader.readAsDataURL(file);
            });
            if(!hasErrors) setDialogOpen(true)
        }
    };
    const handleSnackbarClose = (index) => {
        setErrorMessages((prevMessages) => prevMessages.filter((_, i) => i !== index));
    };

    

    
    const [fileUploadProgress,setFileUploadProgress]=useState(0)
    const onFileUploadProgress = (percent) => {
        setFileUploadProgress(percent)
    };


    const uploadFilesAndSendOneByOne = async () => {
        setUploadingMedia(true)
        for (const file of fileData) {
            if (file?.file) {
                try {
                    setFileData(prevData =>
                        prevData.map(item =>
                            item.file === file.file
                                ? { ...item, status: 'uploading' }
                                : item
                        )
                    );
                    const response = await uploadMedia(file.file,onFileUploadProgress);
                    if (response.status === 200 && response.data) {
                        setFileData(prevData =>
                            prevData.map(item =>
                                item.file === file.file
                                    ? { ...item, ...response.data, status: 'uploaded' }
                                    : item
                            )
                        );
                        if(channel==="whatsapp" && ['image','video','document','audio'].includes(mediaType) && fileData.length>0){
                            sendMessage(roomId,{type:mediaType,[mediaType]:response.data},{channel:channel,roomId:roomId,conversationId:conversationId})
                        }
                        setFileData(prevData =>
                            prevData.map(item =>
                                item.file === file.file
                                    ? { ...item, status: 'sent' }
                                    : item
                            )
                        );
                    } 
                    else {
                        setFileData(prevData =>
                            prevData.map(item =>
                                item.file === file.file
                                    ? { ...item, status: 'failed' }
                                    : item
                            )
                        );
                    }
                } 
                catch (error) {
                    console.error(`Failed to upload ${file.name}:`, error);
                    setFileData(prevData =>
                        prevData.map(item =>
                            item.file === file.file
                                ? { ...item, status: 'failed' }
                                : item
                        )
                    );
                }
            }
        }
        setFileData([])
        setUploadingMedia(false)
        setDialogOpen(false)
    };
    

    const handleDeleteFile = (index) => {
        setFileData((prevData) => {
            const newData = prevData.filter((_, i) => i !== index);
            if (newData.length === 0) {
                setDialogOpen(false)
            }
            return newData;
        });
    };
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <Menu
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {menuItems.map((item) => (
                    <MenuItem key={item.id} onClick={() => handleMediaTypeSelected(item.id)}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText>{item.description}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>

            <Tooltip arrow placement="top" title="Media Message" enterDelay={1000}>
                <IconButton
                    color='secondary'
                    disableRipple
                    sx={{
                        minHeight: '40px',
                        background: "#F5F5F5",
                        borderRadius: "5px",
                        alignSelf: 'flex-start'
                    }}
                    onClick={handleAttachmentClick}
                >
                    {buttonIcon}
                </IconButton>
            </Tooltip>

            {menuItems.map((item) => (
                <input
                    key={item.id}
                    id={`file-input-${item.id}`}
                    type="file"
                    multiple
                    style={{ display: 'none' }}
                    accept={item.accept}
                    onChange={handleFileChange}
                />
            ))}

            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <Typography variant="h6" gutterBottom>
                        Selected Files
                    </Typography>
                    <Grid container spacing={2}>
                        {fileData.map((file, index) => (
                            <Grid item xs={12} key={index}>
                                <Paper variant="outlined" sx={{ padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div>
                                        <Typography variant="body1">{file.fileName}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {file.mimeType} - {(file.size / 1024).toFixed(2)} KB
                                        </Typography>
                                    </div>
                                    <Stack 
                                        direction="row"
                                        sx={{alignItems:'center'}}
                                        spacing={2}
                                    >
                                        <FileStatusIcon status={file.status} value={fileUploadProgress} />
                                        {uploadingMedia===false && file.status === 'pending' && (
                                            <IconButton 
                                                color='error'
                                                disableRipple
                                                sx={{
                                                    minHeight: '40px',
                                                    background: "#F5F5F5",
                                                    borderRadius: "5px",
                                                    alignSelf: 'flex-start'
                                                }}  
                                                onClick={() => handleDeleteFile(index)}
                                            >
                                                <DeleteRounded />
                                            </IconButton>
                                        )}
                                    </Stack>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {fileData.length>0 && uploadingMedia===false &&
                        <Stack
                            direction="row"
                            spacing={2}
                        >
                            <Tooltip arrow title="Cancel" placement='top' enterDelay={1000}>
                                <IconButton 
                                    color='secondary'
                                    disableRipple
                                    sx={{
                                        minHeight: '40px',
                                        background: "#F5F5F5",
                                        borderRadius: "5px",
                                        alignSelf: 'flex-start'
                                    }} 
                                    onClick={handleDialogClose}
                                >
                                    <CloseRounded/>    
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow title="Upload and Send Media" placement='top' enterDelay={1000}>
                                <IconButton 
                                    color='secondary'
                                    disableRipple
                                    sx={{
                                        minHeight: '40px',
                                        background: "#F5F5F5",
                                        borderRadius: "5px",
                                        alignSelf: 'flex-start'
                                    }}  
                                    onClick={uploadFilesAndSendOneByOne} 
                                >
                                    <SendRounded/>    
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    }
                </DialogActions>
            </Dialog>

            {errorMessages.map((errorMessage, index) => (
                <Snackbar
                    key={index}
                    open={true}
                    autoHideDuration={6000}
                    onClose={() => handleSnackbarClose(index)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert 
                        onClose={() => handleSnackbarClose(index)} 
                        severity="error"  
                        sx={{}}
                    >
                        <Typography
                            sx={{
                                fontFamily:"DM Sans Light",
                                fontSize:'14px',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,        
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',  
                            }}
                        >
                        {errorMessage}
                        </Typography>
                    </Alert>
                </Snackbar>
            ))}
        </React.Fragment>
    );
}

MediaMessageInitializer.defaultProps = {
    channel:'whatsapp',
    roomId:null,
    conversationId:null,
    buttonIcon: <AttachFileRounded />,
    sendMessage: (fileData) => { console.log("Default Message Trigger Handler Invoked with files:", fileData); },
};

export default MediaMessageInitializer;

import React,{useState,useEffect} from 'react';
import { Dialog,DialogContent,DialogActions,Typography,Slide, IconButton, Tooltip, Stack,CircularProgress} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { extractFileExtension } from '../../utils/fileFunctions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MediaModal({ open,onClose, mediaType, mediaURL,mimeType}) {

    const [mediaLoaded, setMediaLoaded] = useState(false);

    useEffect(() => {
        setMediaLoaded(false);
    }, [mediaURL, mediaType]);

    const handleMediaLoad = () => {
        setMediaLoaded(true);
    };
    const renderMediaContent = () => {
        return (
            <React.Fragment>
                {!mediaLoaded && <CircularProgress sx={{color:'#DF6437'}}/>}
                {(() => {
                    switch (mediaType) {
                        case 'image':
                            return (
                                <img
                                    src={mediaURL}
                                    alt="Image"
                                    style={{
                                        height: '100%',
                                        width: '80%',
                                        objectFit: 'contain',
                                    }}
                                    onLoad={handleMediaLoad}
                                />
                            );
                        case 'sticker':
                            return (
                                <img
                                    src={mediaURL}
                                    alt="Sticker"
                                    style={{
                                        height: '100%',
                                        width: '80%',
                                        objectFit: 'contain',
                                    }}
                                    onLoad={handleMediaLoad}
                                />
                            );
                        case 'video':
                            return (
                                <video
                                    controls
                                    style={{ 
                                        height: '100%',
                                        width: '80%',
                                        objectFit: 'contain',
                                    }}
                                    onCanPlay={handleMediaLoad}
                                >
                                    <source src={mediaURL} type={mimeType} />
                                    Your browser does not support the video tag.
                                </video>
                            );
                        case 'audio':
                            return (
                                <audio controls style={{ width: '100%' }} onCanPlay={handleMediaLoad}>
                                    <source src={mediaURL} type={mimeType} />
                                    Your browser does not support the audio element.
                                </audio>
                            );
                        case 'document':
                            return (
                                <React.Fragment>
                                    {mimeType.startsWith('application')||mimeType.startsWith('text') ? (
                                        <iframe
                                            src={`https://docs.google.com/viewer?url=${encodeURIComponent(mediaURL)}&embedded=true&timestamp=${new Date().getTime()}`}
                                            style={{ width: '100%', height: '100%', border: 'none' }}
                                            title="PDF Preview"
                                            onLoad={handleMediaLoad}
                                        />
                                    ) : mimeType.startsWith('image') ? (
                                        <img
                                            src={mediaURL}
                                            alt="Image"
                                            style={{
                                                height: '100%',
                                                width: '80%',
                                                objectFit: 'contain',
                                            }}
                                            onLoad={handleMediaLoad}
                                        />
                                    ) : mimeType.startsWith('video') ? (
                                        <video
                                            controls
                                            style={{ 
                                                height: '100%',
                                                width: '80%',
                                                objectFit: 'contain',
                                            }}
                                            onCanPlay={handleMediaLoad}
                                        >
                                            <source src={mediaURL} type={mimeType} />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : mimeType.startsWith('audio') ? (
                                        <audio controls style={{ width: '100%' }} onCanPlay={handleMediaLoad}>
                                            <source src={mediaURL} type={mimeType} />
                                            Your browser does not support the audio element.
                                        </audio>
                                    ) 
                                     : (
                                        <Typography>No preview available for this document type.</Typography>
                                    )}
                                </React.Fragment>
                            );
                        default:
                            return <Typography>No preview available for this type of media.</Typography>;
                    }
                })()}
            </React.Fragment>
        );
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            TransitionComponent={Transition}
            maxWidth="md"
            fullWidth
        >
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection:'column',
                    height:'80vh',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                {renderMediaContent()}
            </DialogContent>
            <DialogActions>
                {mediaURL && (
                        <Stack
                            direction="row"
                            spacing={2}
                        >
                            <Tooltip title={`Download ${mediaType}`} placement='left' arrow>
                                <IconButton
                                    href={mediaURL}
                                    download
                                    target="_blank"
                                    size="small"
                                    color='secondary'
                                    sx={{
                                        background: "#F5F5F5",
                                        borderRadius:'5px',
                                    }}
                                >
                                    <DownloadRoundedIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Close" placement='top' arrow>
                                <IconButton 
                                    disableFocusRipple
                                    disableRipple
                                    disableTouchRipple
                                    onClick={onClose}
                                    size="small"
                                    color='secondary'
                                    sx={{
                                        background: "#F5F5F5",
                                        borderRadius:'5px'
                                    }}
                                >
                                    <CloseRoundedIcon/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default MediaModal;

import axios from 'axios';
import { SERVER_BASE_URL } from '../constants.js';
let authToken;

export function setAuthToken(token) {
  authToken = token;
}

const axiosInstance = axios.create({
  baseURL: `${SERVER_BASE_URL}`,
  timeout: 10000, 
});

// Add a request interceptor to attach the Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    if (authToken) {
      config.headers['authorization'] = authToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

import React, { useState, useRef, useEffect,useCallback,useMemo } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { AutoSizer } from 'react-virtualized';
import { VariableSizeList as List } from "react-window";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import ConversationHeader from './ConversationHeader.jsx';
import ConversationFooter from './ConversationFooter.jsx';
import ConversationInfo from './ConversationInfo.jsx';
import { useMediaQuery, useTheme } from '@mui/material';
import AppNotificationMessage from '../Common/AppNotificationMessage.jsx';
import UserMessage from '../Common/UserMessage.jsx';
import ChatBotMessage from '../Common/ChatBotMessage.jsx';
import CustomerMessage from '../Common/CustomerMessage.jsx';
import OtherUserMessage from '../Common/OtherUserMessage.jsx';
import { formatDay } from '../../utils/dateTimeFunctions.js';
import { useAppStore } from './AppStore.jsx';


const styles = {
  metaDataAndActionLog:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    alignItems:'center'
  },
  rhs: {
    display: "flex",
    flex: "0 0 auto",
    justifyContent: "flex-end",
    width: "100%"
  },
  lhs: {
    display: "flex",
    flex: "0 0 auto",
    justifyContent: "flex-start",
    width: "100%"
  },
  message: {
    display: "flex",
    width: "65%",
    marginLeft:'8px',
    marginRight:'8px'
  },
};


function ConversationWindow({
  selectedConversation,
  setSelectedConversation,
}) {
  const authUser = useAuthUser();
  const user = authUser?.user ?? null;
  const {agents, fetchEvents, useRoomById, useEventsByRoomId,useEventsCountByRoomId} = useAppStore();
  const room = useRoomById(selectedConversation);
  const rawEvents = useEventsByRoomId(selectedConversation);
  const eventsCount = useEventsCountByRoomId(selectedConversation)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showInfo, setShowInfo] = useState(isMobile ? false : true);
  const handleInfoToggle = () => {
    setShowInfo((prev) => !prev);
  };

  const getAgent = (userId) => {
    return agents.find(agent => agent.userId === userId);
  };


  let lastMessageDate = null;

  const events = useMemo(() => {
    let lastMessageDate = null;
    const eventList = [];
    
    rawEvents.forEach((event) => {
      const messageDate = new Date(event.timestamp);
      const shouldShowDate = !lastMessageDate || messageDate.toDateString() !== lastMessageDate?.toDateString();
      if (shouldShowDate) {
        eventList.push({ type: 'dateTimeSeparator', date: messageDate });
      }
      eventList.push(event);
      lastMessageDate = messageDate;
    });

    return eventList;
  }, [rawEvents]);

 

  function Row({ index, style }) {
    const rowRef = useRef({});
    const event = events[index];
    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef]);

    if (event.type === 'dateTimeSeparator') {
      return (
        <div style={style}>
           <div key={index} ref={rowRef} style={styles.metaDataAndActionLog}>
              <Grid item xs={12} sx={{ textAlign: 'center'}}>
                <Typography sx={{fontFamily:'DM Sans Light',fontSize:'12px'}} color="textSecondary">
                  {formatDay(event.date)}
                </Typography>
              </Grid>
           </div>
        </div>
      );
    }

    return (
      <div style={style}>
        {event.userId === user?.userId ? (
          <div key={index} ref={rowRef} style={styles.rhs}>
            <div style={styles.message}>
              <UserMessage id={event.documentId} event={event} />
            </div>
          </div>
        ) : (
          <div key={index} ref={rowRef} style={styles.lhs}>
            <div style={styles.message}>
              {event.userId === 'mercuriCXAgent' && (
                <ChatBotMessage id={event.documentId} event={event} variables={room.variables} />
              )}
              {event.userId === 'mercuriNotification' && (
                <AppNotificationMessage id={event.documentId} event={event} variables={room.variables} />
              )}
              {event.userId === room.conversationId && (
                <CustomerMessage id={event.documentId} event={event} variables={room.variables} />
              )}
              {event.userId !== user.userId && getAgent(event.userId) && (
                <OtherUserMessage
                  id={event.documentId}
                  user={getAgent(event.userId)}
                  event={event}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  const listRef = useRef(null);
  const rowHeights = useRef({});

  function getRowHeight(index) {
    let rowHeight = rowHeights.current[index] + 24 || 82;
    return rowHeight;
  }

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }


  const scrollToBottom = useCallback(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(events.length - 1, "end");
    }
  }, [events.length]);

  const loadingMoreRef = useRef(false); // Using ref for loadingMore
  const hasMounted = useRef(false);

  const handleItemsRendered = useCallback(
    async ({ visibleStartIndex }) => {
      const currentScrollOffset = listRef.current ? listRef.current._outerRef.scrollTop : null;

      // Check if we should load more
      if (visibleStartIndex === 0 && !loadingMoreRef.current && eventsCount > rawEvents.length) {
        loadingMoreRef.current = true; // Set loading to true

        await fetchEvents(selectedConversation, rawEvents[0]?.documentId);
        
        loadingMoreRef.current = false; // Set loading to false

        // Restore scroll position if needed
        if (listRef.current && currentScrollOffset !== null) {
          listRef.current.scrollTo(currentScrollOffset);
        }
      }
    },
    [eventsCount, rawEvents.length, selectedConversation]
  );

  useEffect(() => {
    if (rawEvents.length === 0) {
      fetchEvents(selectedConversation, null);
    }
    else{
      scrollToBottom(); 
    }
  }, [rawEvents, selectedConversation, fetchEvents]);
  
  return (
    <React.Fragment>
      {room && Object.keys(room).length > 0 && (
        <Grid container direction="row" style={{ height: "100%" }}>
          <Grid
            item
            container
            direction="column"
            xs={!isMobile && showInfo ? 8 : 12}
            sm={!isMobile && showInfo ? 6 : 12}
            md={!isMobile && showInfo ? 7 : 12}
            lg={!isMobile && showInfo ? 8 : 12}
            xl={!isMobile && showInfo ? 8 : 12}
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 0,
                minHeight: `10vh`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ConversationHeader
                selectedConversation={selectedConversation}
                setSelectedConversation={setSelectedConversation}
                handleInfoToggle={handleInfoToggle}
                room={room}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      ref={listRef} 
                      width={width}
                      height={height}
                      itemCount={events.length}
                      itemSize={getRowHeight}
                      //onItemsRendered={handleItemsRendered} 
                      style={{
                        scrollbarWidth: 'thin',
                        scrollbarColor: '#DF6437 #FFFFFF', 
                      }}
                    >
                    {Row}
                  </List>
                  )}
                </AutoSizer>
            </Box>
            <Box
              sx={{
                position: "sticky",
                bottom: 0,
                minHeight: `10vh`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <ConversationFooter room={room}/>
            </Box>
          </Grid>
          {!isMobile && showInfo ? (
            <Grid
              item
              xs={4}
              sm={6}
              md={5}
              lg={4}
              xl={4}
              sx={{
                display: "flex",
                overflowY: "auto",
                border: "1px solid #F2F2F2",
              }}
            >
              <ConversationInfo handleInfoToggle={handleInfoToggle} room={room}/>
            </Grid>
          ) : null}
        </Grid>
      )}
    </React.Fragment>
  );
}

export default ConversationWindow;

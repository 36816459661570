import { Grid,Box, Button, Card, CardActions, CardContent, FormControl, Typography } from '@mui/material';
import React from 'react';

function ContactsMessage({ event }) {
    return (
        <Grid container direction="row" spacing={2} mt={1}>
            {event?.contacts.map((contact, index) => (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card key={index}
                        sx={{
                            border: '1px solid #E3E3E3',
                            borderRadius: '10px',
                            boxShadow:'none'
                        }}
                    >
                        <CardContent sx={{minHeight:'50px'}}>
                            {contact?.formattedName && (
                                <Typography sx={{fontSize: '14px',mb:1}}>
                                    {contact.formattedName}
                                </Typography>
                            )}
                            {contact?.org?.company && (
                                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                <Typography sx={{fontSize: '12px'}}>
                                    Company
                                </Typography>
                                <Typography sx={{fontSize: '12px'}}>
                                    {contact.org.company}
                                </Typography>
                            </Box>
                            )}
                            {contact?.phones && (
                                <React.Fragment>
                                    {contact?.phones.map((phone,pIndex)=>(
                                        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}} key={pIndex}>
                                            <Typography sx={{fontSize: '12px'}}>
                                                {phone.type} 
                                            </Typography>
                                            <Typography sx={{fontSize: '12px'}}>
                                                {phone.phone}
                                            </Typography>
                                        </Box>
                                    ))}
                                </React.Fragment>
                            )}
                        </CardContent>
                        {/* <CardActions>
                        <FormControl fullWidth>
                                <Button>View</Button>
                        </FormControl>
                        </CardActions> */}
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}

export default ContactsMessage;

import React from 'react'
import {AppBar, Typography} from '@mui/material';
import { capitalizeWords } from '../../utils/stringFunctions';

function AppHeader({socketStatus,headerHeight}) {
    return (
        <AppBar
            position="fixed"
            contained
            sx={{
                background:'#FFFFFF',
                zIndex:'1300',
                color:socketStatus==="connected"?'#009900':socketStatus==="connecting"?"#ff9800":"#ff0000",
                minHeight:`${headerHeight}px`,
                maxHeight:`${headerHeight}px`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent:"center",
                alignItems:'center',
                boxShadow:'none',
            }}
            > 
           <Typography sx={{fontFamily:'DM Sans Medium',fontSize:'12px'}}>{capitalizeWords(socketStatus)}</Typography>
        </AppBar>
    )
}

export default AppHeader
import React, { useState } from 'react';
import { Button, Stack, Snackbar } from '@mui/material';

const ButtonStack = ({ item }) => {
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        action: null,
    });

    const handleClose = () => {
        setSnackbar({ ...snackbar, open: false });
        if (snackbar.action) {
            snackbar.action();
        }
    };

    const handleButtonClick = (button) => {
        const showAlert = (message, action) => {
            setSnackbar({
                open: true,
                message,
                action,
            });
        };

        switch (button.type) {
            case "QUICK_REPLY":
                showAlert(`Quick reply button: ${button.text}`);
                break;
            case "URL":
                showAlert('Opening URL...');
                setTimeout(() => {
                    window.open(button.url, "_blank");
                }, 2000); 
                break;
            case "PHONE_NUMBER":
                showAlert('Dialing phone number...');
                setTimeout(() => {
                    window.location.href = `tel:${button.phoneNumber}`;
                }, 2000);
                break;
            case "COPY_CODE":
                showAlert('Code copied to clipboard');
                navigator.clipboard.writeText(button.code);
                break;
            default:
                console.log("Unknown button type");
        }
    };

    return (
        <>
            <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
                {item.buttons?.map((button, btnIndex) => (
                    <Button
                        size='small'
                        variant='outlined'
                        color='secondary'
                        sx={{
                            fontFamily: 'DM Sans Medium',
                            fontSize: '14px',
                            textTransform: 'none',
                            color: 'black'
                        }}
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        onClick={() => handleButtonClick(button)}
                    >
                        {button.text}
                    </Button>
                ))}
            </Stack>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snackbar.open}
                message={snackbar.message}
                autoHideDuration={3000}
                onClose={handleClose}
            />
        </>
    );
};

export default ButtonStack;

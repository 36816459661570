export const mimeFormats = {
    "audio/aac": ".aac",
    "audio/amr": ".amr",
    "audio/mpeg": ".mp3",
    "audio/mp4": ".m4a",
    "audio/ogg": ".ogg",
    "text/plain": ".txt",
    "text/csv": ".csv",
    "text/tab-separated-values":".tsv",
    "application/vnd.ms-excel": ".xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
    "application/vnd.oasis.opendocument.spreadsheet":".ods",
    "application/msword": ".doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx",
    "application/vnd.ms-powerpoint": ".ppt",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": ".pptx",
    "application/pdf": ".pdf",
    "image/jpeg": ".jpeg",
    "image/png": ".png",
    "image/webp": ".webp",
    "video/3gp": ".3gp",
    "video/mp4": ".mp4",
};
    
export function getFileExtension(file) {
    try{
        const parts = file.split('.');
        return parts.length > 1 ? `.${parts.pop()}` : '';
    }
    catch(e){
        console.error('Error extracting Extension:', e);
        return  null
    }  
}

export function extractFileExtension(mimeType) {
    try{
        const file = mimeFormats[mimeType];
        return file || null;
    }
    catch(e){
        console.error('Error extracting File Extension:', e);
        return  null
    }  
}
  
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    TextField,
    Typography,
  } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import { SERVER_BASE_URL } from "../../constants.js";
import axios from "axios";
import isEmail from "validator/lib/isEmail";
import SendNotification from "../Common/AppNotification.jsx";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import botIcon from "../../assets/onboardingLogo.png"
  
function LoginPage() {
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const [email, setemail] = useState("");
    const [incorrectEmail, setIncorrectEmail] = useState(false);
    const [incorrectPassword, setIncorrectPassword] = useState(false);
    const [errormsg, setErrorMsg] = useState("");
    const [passworderrormsg, setPasswordErrorMsg] = useState("");
    const [password, setpassword] = useState();
    const [loginError, setLoginError] = useState(false);
    const [loginErrorMsg, setLoginErrorMsg] = useState("");
    const navigate = useNavigate();

    const signIn = useSignIn();

    const isAuthenticated=useIsAuthenticated();
    useEffect(()=>{
      if(isAuthenticated){
        navigate('/conversation')
      }
    },[isAuthenticated])

    const handleSignIn = async () => {
      if (!email || !password) {
        if (!email) {
          setLoginErrorMsg("Email Cannot be empty");
          setLoginError(true);
        }
  
        if (!password) {
          setLoginErrorMsg("Password Cannot be empty");
          setLoginError(true);
        }
      } else if (!isEmail(email)) {
        setLoginErrorMsg("Invalid Email ");
        setLoginError(true);
      } else {
        try {
          const response = await axios.post(
            `${SERVER_BASE_URL}/sharedInbox/auth/login`,
            {
              email: email,
              password: password,
            }
          );
          if (response.status === 200) {
            // if(signIn({
            //     auth:{
            //       token:response.data?.accessToken,
            //       type:'Bearer',
            //     },
            //     refresh: response?.data?.refreshToken,
            //     userState: response.data?.userState,
            //     isSignIn: true,
            //     isUsingRefreshToken: true,
            // })){
            //   navigate('/conversation')
            // }
            if (
              signIn({
                auth: {
                  token: response.data?.authToken,
                  type: "Bearer",
                },
                userState: response.data?.userState,
                isSignIn: true,
                isUsingRefreshToken: false,
              })
            ) {
              navigate("/conversation");
            }
            // if (
            //   signIn({
            //     auth: {
            //       token: response.data?.authData?.refreshToken,
            //       type: "Bearer",
            //     },
            //     userState: response.data?.authData?.userState,
            //     isSignIn: true,
            //     isUsingRefreshToken: false,
            //   })
            // ) {
            //   navigate("/conversation");
            // }
          } else if (response.status === 201) {
            setLoginError(true);
            setLoginErrorMsg(
              "User Not Found,check the credentails or Please Sign Up !"
            );
          } else if (response.status === 202) {
            setLoginError(true);
            setLoginErrorMsg(
              "Inavlid Credentails, Please check username and password"
            );
          } else {
            console.error("Login Error:", response.data.error);
          }
        } catch (error) {
          console.error("Login Error:", error);
        }
      }
    };

    const [showpassword, setshowPassword] = useState(false);
  
    return (
      <Box
        sx={{
          flex: 1,
          height: "100vh",
          maxHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loginError && (
          <SendNotification
            openSnackBar={loginError}
            severity="error"
            message={loginErrorMsg}
            setOpenSnackBar={() => setLoginError(false)}
          />
        )}
  
        <Box
          sx={{
            minWidth: "25%",
            height: "auto",
            borderRadius: "15px",
            border: "1px solid #A3AED0",
            p: 4,
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
            gap: 2.5,
          }}
        >
         <Box
            sx={{
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
              alignItems:'left'
            }}
        >
          <img src={botIcon} width={200} alt=""/>
        </Box>

          <Typography
            variant="h5"
            sx={{
              fontFamily: "DM Sans Medium",
              fontWeight: 900,
            }}
          >
            Login
          </Typography>
  
          <FormControl fullWidth sx={{gap:1}}>
              <FormLabel>Email *</FormLabel>
              <TextField
                size="small"
                color="secondary"
                error={incorrectEmail}
                inputRef={emailRef}
                type="email"
                onChange={(e) => {
                  setemail(e.target.value);
                  setIncorrectEmail(false);
                  setErrorMsg("");
                }}
                placeholder="Enter Your Email Id"
              />
          </FormControl>
  
          <FormControl fullWidth sx={{gap:1}}>
              <FormLabel>Password *</FormLabel>
              <TextField
                size="small"
                color="secondary"
                error={incorrectPassword}
                inputRef={passwordRef}
                onChange={(e) => {
                  setpassword(e.target.value);
                  setIncorrectPassword(false);
                  setPasswordErrorMsg("");
                }}
                type={showpassword ? "text" : "password"}
                placeholder="Enter Your Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={() => setshowPassword((state) => !state)}
                      >
                        {showpassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
          </FormControl>
          <FormControl fullWidth>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#DF6437",
                },
              }}
              onClick={handleSignIn}
            >
              Login
            </Button>
          </FormControl>
        </Box>
      </Box>
    );
  }
  
  export default LoginPage;
  
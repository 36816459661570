import React,{useState,useEffect} from 'react'
import {useSearchParams } from "react-router-dom";
import AppBody from "./Components/Main/AppBody.jsx";
import AppHeader from "./Components/Main/AppHeader.jsx";
import AppFooter from "./Components/Main/AppFooter.jsx";
import {Box} from '@mui/material';
import { AppContextProvider } from './Components/Main/AppStore.jsx';



export default function AppFrame() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [appView, setAppView] = useState(searchParams.get("view") ?? "tab");
  const [showHeader, setShowHeader] = useState(true);
  const [headerHeight, setHeaderHeight] = useState(20);
  const [showFooter, setShowFooter] = useState(true);
  const [footerHeight, setFooterHeight] = useState(20);

  const [socketStatus, setSocketStatus] = useState("");

  useEffect(() => {
    if (appView === "iframe") {
      setShowHeader(false);
      setShowFooter(false);
    }
  }, [appView]);

  return (
      <AppContextProvider>
        {showHeader && (
          <AppHeader socketStatus={socketStatus} headerHeight={headerHeight} />
        )}
        <Box
          sx={{
            flex: 1,
            height: "100vh",
            maxHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AppBody
            appView={appView}
            showHeader={showHeader}
            headerHeight={headerHeight}
            showFooter={showFooter}
            footerHeight={footerHeight}
            socket={socketStatus}
            setSocketStatus={setSocketStatus}
          />
        </Box>
        {showFooter && (
          <AppFooter
            text="Powered by Mercuri"
            redirectLink="https://www.mercuri.cx/"
            footerHeight={footerHeight}
          />
        )}
      </AppContextProvider>
  );
}

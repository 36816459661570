import { Box, Typography } from '@mui/material';
import React from 'react';
import MediaCard from './MediaCard';
import ButtonStack from './ButtonsStack';
import GoogleMapView from './GoogleMapView';


function TemplateMessage({ event }) {

    const order = ['HEADER', 'BODY', 'FOOTER', 'BUTTONS'];
    const sortedTemplate = event?.template?.sort(
        (a, b) => order.indexOf(a?.type) - order.indexOf(b?.type)
    );

    return (
        <React.Fragment>
            {sortedTemplate?.map((item, index) => {
                switch (item.type) {
                    case 'HEADER':
                        return (
                            <Box key={index} sx={{ mb: 2 }}>
                                {item.format === 'TEXT' && (
                                    <Typography sx={{ fontFamily: 'DM Sans Medium', fontSize: '14px', whiteSpace: 'pre-wrap' }}>
                                        {item.text}
                                    </Typography>
                                )}
                                {item.format === 'MEDIA' && ['IMAGE', 'VIDEO', 'DOCUMENT'].includes(item.subFormat) && (
                                    <MediaCard item={item} />
                                )}
                                {item.format === 'MEDIA' && item.subFormat === 'LOCATION' && (
                                    <Box
                                        sx={{
                                            background:"#FFFFFF",
                                            p:0.5,
                                            border:'1px solid #F2F2F2',
                                            borderRadius:"8px" ,
                                        }}
                                    >
                                        {(() => {
                                            const center = {
                                                lat: isNaN(parseFloat(item.location[0])) ? 0 : parseFloat(item.location[0]),
                                                lng: isNaN(parseFloat(item.location[1])) ? 0 : parseFloat(item.location[1])
                                            };

                                            return (
                                                <React.Fragment>
                                                    <Box
                                                        sx={{
                                                            width:'100%',
                                                            height:'250px',
                                                        }}
                                                    >
                                                        <GoogleMapView center={center} />
                                                    </Box>
                                                    {item.location[2] && (
                                                        <Typography sx={{ fontFamily: 'DM Sans Medium', fontSize: '14px', whiteSpace: 'pre-wrap' }}>
                                                            {item.location[2]}
                                                        </Typography>
                                                    )}
                                                    
                                                    {item.location[3] && (
                                                        <Typography sx={{ fontFamily: 'DM Sans Light', fontSize: '12px', whiteSpace: 'pre-wrap' }}>
                                                            {item.location[3]}
                                                        </Typography>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })()}
                                    </Box>
                                )}

                            </Box>
                        );
                    case 'BODY':
                        return (
                            <Box key={index}>
                                <Typography sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>
                                    {item.text}
                                </Typography>
                            </Box>
                        );
                    case 'FOOTER':
                        return (
                            <Box key={index} sx={{ mt: 1, mb: 1 }}>
                                <Typography sx={{ fontFamily: 'DM Sans Light', fontSize: '12px', whiteSpace: 'pre-wrap' }}>
                                    {item.text || item.url}
                                </Typography>
                            </Box>
                        );
                    case 'BUTTONS':
                        return <ButtonStack item={item} />;
                    default:
                        return null;
                }
            })}
        </React.Fragment>
    );
}

export default TemplateMessage;
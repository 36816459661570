import React, { useState, useEffect } from "react";
import { Box, IconButton, Popover, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import AppExtensionsSDK, { Command } from "@pipedrive/app-extensions-sdk";

export default function PipedriveResize({ sdkId }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(100); // Initial zoom level
  const [width, setWidth] = useState(800); // Initial width
  const [height, setHeight] = useState(700); // Initial height
  const sdk = new AppExtensionsSDK({identifier: sdkId}).initialize({
        size: { height: parseInt(height), width: parseInt(width) },
  });
  const maxWidth = 800;
  const maxHeight = 700;
  const minWidth = 200;
  const minHeight = 70;

  // Update dimensions based on zoom level
  useEffect(() => {
    const updateDimensions = async () => {
      await setDimension();
      await resize();
    };
    updateDimensions();
  }, [zoomLevel]);

  // Handles opening the popover
  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handles closing the popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIncrease = () => {
    setZoomLevel((prev) => Math.min(prev + 10, 100));
  };

  const handleDecrease = () => {
    setZoomLevel((prev) => Math.max(prev - 10, 70));
  };

  const handleReset = () => {
    setZoomLevel(100);
  };

  const open = Boolean(anchorEl);
  const id = open ? "resize-popover" : undefined;

  const resize = async () => {
    const response = await sdk.execute(Command.RESIZE, {
      height: parseInt(height),
      width: parseInt(width),
    });
  };

  const setDimension = async () => {
    let newWidth, newHeight;

    switch (zoomLevel) {
      case 70:
        newWidth = 560;
        newHeight = 490;
        break;
      case 80:
        newWidth = 640;
        newHeight = 560;
        break;
      case 90:
        newWidth = 720;
        newHeight = 630;
        break;
      default:
        // Default to 100% zoom level if no case matches
        newWidth = maxWidth;
        newHeight = maxHeight;
        break;
    }

    setWidth(newWidth);
    setHeight(newHeight);
  };

  return (
    <React.Fragment>
        <IconButton
            aria-describedby={id}
            onClick={handleIconClick}
            size="small"
            color="secondary"
            sx={{
                minHeight:'40px',
                background: "#F5F5F5",
                borderRadius: "5px",
            }} 
            disableRipple 
        >
          <AspectRatioIcon/>
        </IconButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1.5,
              bgcolor: "#333",
              color: "#fff",
              borderRadius: 2,
            }}
          >
            <IconButton
              onClick={handleDecrease}
              size="small"
              sx={{ color: "#fff" }}
              disabled={zoomLevel <= 70}
            >
              <RemoveIcon fontSize="small" />
            </IconButton>
            <Typography variant="body2" sx={{ mx: 1, fontWeight: "bold" }}>
              {zoomLevel}%
            </Typography>
            <IconButton
              onClick={handleIncrease}
              size="small"
              sx={{ color: "#fff" }}
              disabled={zoomLevel >= 100}
            >
              <AddIcon fontSize="small" />
            </IconButton>
            <Button
              onClick={handleReset}
              size="small"
              sx={{
                ml: 2,
                color: "#90CAF9",
                borderColor: "#90CAF9",
                borderRadius: 4,
                padding: "2px 8px",
                fontSize: "0.8rem",
                textTransform: "none",
                border: "1px solid",
              }}
            >
              Reset
            </Button>
          </Box>
        </Popover>
    </React.Fragment>
  );
}
import React from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import {PictureAsPdf,Slideshow,DocumentScanner,GridOn,InsertDriveFile,GetAppRounded ,TextSnippetRounded,FilePresentRounded} from '@mui/icons-material';
import { getFileNameFromURL,getFileExtensionFromURL } from '../../utils/stringFunctions.js';

const getFileIcon = (url) => {
    const extension = getFileExtensionFromURL(url);
    switch (extension) {
        case 'txt':
            return <TextSnippetRounded/>;
        case 'pdf':
            return <PictureAsPdf/>;
        case 'doc':
        case 'docx':
            return <DocumentScanner/>;
        case 'ppt':
        case 'pptx':
            return <Slideshow/>;
        case 'xls':
        case 'xlsx':
            return <GridOn/>;
        default:
            return <FilePresentRounded/>; 
    }
};

function MediaCard({ item }) {
    const fileName=getFileNameFromURL(item.url) || 'Untitled Document'
    
    const [mediaError, setMediaError] = React.useState(false); // State to track errors

    if (mediaError || !item.url) {
        return null;
    }

    const renderMediaContent = () => {
        switch (item.subFormat) {
            case 'IMAGE':
                return (
                    <img
                        src={item.url}
                        alt="Template Header Image"
                        style={{ width: '100%', height: '100%', objectFit: 'contain',borderRadius:'5px' }}
                        onError={() => setMediaError(true)}
                    />
                );
            case 'VIDEO':
                return (
                    <video
                        controls
                        style={{ width: '100%', height: '100%', objectFit: 'contain',borderRadius:'5px' }}
                        title="Template Header Video"
                        onError={() => setMediaError(true)}
                    >
                        <source src={item.url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                );
            case 'DOCUMENT':
                return (
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            background:"#FFFFFF",
                            p:2,
                            border:'1px solid #F2F2F2',
                            justifyContent:'space-between',
                            alignItems:'center',
                            borderRadius:"8px" ,
                            cursor:'pointer'
                        }}
                    >
                            <Box
                                sx={{
                                    display:'flex',
                                    flexDirection:'row',
                                    justifyContent:'flex-start',
                                    alignItems:'center',
                                    gap:2,
                                    flexGrow: 1,
                                    minWidth: 0,
                                }}
                            >
                                <IconButton
                                    disableRipple
                                    size="small"
                                    sx={{
                                        minHeight: '40px',
                                        background: "#F5F5F5",
                                        borderRadius: "5px",
                                    }}
                                >
                                    {getFileIcon(item.url)}
                                </IconButton>
                                <Typography 
                                    noWrap
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        minWidth: 0, 
                                        flexGrow: 1, 
                                    }}
                                >
                                    {fileName}
                                </Typography>
                            </Box>
                            <IconButton
                                disableRipple
                                href={item.url}
                                target='_blank'
                                download
                                color="secondary" 
                                size="small"
                                sx={{
                                    minHeight: '40px',
                                    background: "#F5F5F5",
                                    borderRadius: "5px",
                                }}
                            >
                                <GetAppRounded/>
                            </IconButton>
                    </Stack>
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ width: '100%'}}>
            {renderMediaContent()}
        </Box>
    );
}

export default MediaCard;

import React,{useEffect, useState,useRef} from 'react';
import {useSearchParams } from "react-router-dom";

import NavigationSearchFilter from '../Common/NavigationSearchFilter.jsx';
import NotificationBar from '../Common/NotificationBar.jsx';
import ConversationCard from '../Common/ConversationCard.jsx';
import {Grid,Box, Typography,IconButton, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import {useMediaQuery,useTheme} from '@mui/material';
import ConversationWindow from './ConversationWindow.jsx';
import { getAgents as getAgentsFromServer, getRooms as getRoomsFromServer,getEvents as getEventsFromServer,getNotifications as getNotificationsFromServer,deleteNotifications as deleteNotificationsInServer, updateConversationStatus, updateRoomAgentsAssignment, getParticipants,subscribeToFCMTopic} from '../../services/conversations.js';

import {useAppStore } from './AppStore.jsx';

import socket from "../../socket/index.js";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import LogoutButton from '../Common/LogoutButton.jsx';
import TabPanel,{a11yProps} from "../Common/TabPanel.jsx";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";

import { CircularProgress } from '@mui/material';
import { messaging } from '../../services/firebase.js';
import { getToken , onMessage } from 'firebase/messaging';
import {SERVER_BASE_URL,FIREBASE_MESSAGING_VAPID_KEY} from '../../constants.js';
import sharedInboxIcon from '../../assets/sharedInboxIcon.png';
import notificationSound from '../../assets/messageNotification.mp3';


function AppBody({
  appView,
  headerHeight,
  showHeader,
  footerHeight,
  showFooter,
  setSocketStatus,
}) {

  const [searchParams, setSearchParams] = useSearchParams();
  const paramConversationId = searchParams.get("conversationId");
  
  const [selectedWindow, setSelectedWindow] = useState("rooms");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const authUser = useAuthUser();
  const user = authUser?.user ?? null;
  const authHeader = useAuthHeader();
  
  const [selectedConversation, setSelectedConversation] = useState(null);

  const {loadingContext,rooms,useRoomsCount,increaseRoomsCount,upsertRoom,updateRooms, upsertEvent ,increaseEventsCountInContext,useChannels,addNotification,deleteNotificationByRoomId} = useAppStore();
  const channels=useChannels("ref")
  const availableChannels = Object.keys(channels).filter((key) => channels[key]);

  const roomsCount=useRoomsCount(rooms[rooms?.length - 1]?.documentId)

  const [tab, setTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const searchFilter = (value) => {
    setSearchQuery(value);
  };
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };
  const filterConversations = () => {
    if (!searchQuery) return rooms;
    const escapedQuery = escapeRegExp(searchQuery);
    const regex = new RegExp(escapedQuery, "i");
    return rooms?.filter((conversation) =>
      regex.test(JSON.stringify(conversation))
    );
  };

    // const conversationAppend = (documentId, conversation) => {
  //   var updatedConversations = [];
  //   setConversations((prevConversations) => {
  //     const conversationIndex = prevConversations.findIndex(
  //       (conv) => conv.documentId === documentId
  //     );
  //     if (conversationIndex === -1) {
  //       updatedConversations = [...prevConversations, conversation];
  //     } else {
  //       updatedConversations = [
  //         ...prevConversations.slice(0, conversationIndex),
  //         { ...prevConversations[conversationIndex], ...conversation },
  //         ...prevConversations.slice(conversationIndex + 1),
  //       ];
  //     }
  //     updatedConversations.sort((a, b) => {
  //       const aTimestamp = new Date(a.timestamp || Date.now());
  //       const bTimestamp = new Date(b.timestamp || Date.now());
  //       return bTimestamp - aTimestamp;
  //     });
  //     return updatedConversations;
  //   });
  //   return updatedConversations;
  // };

  const conversationMessageHandler = async(response) => {
    //console.log("Message Handler Response",JSON.stringify(response,null,2))
    var existingEvent = false
    let existingRoom = await upsertRoom(response.conversation)
    if(response.event){
      existingEvent = await upsertEvent(response.documentId,response.event)
      if(!existingRoom){
        increaseRoomsCount()
      }
      if(!existingEvent){
        increaseEventsCountInContext(response.documentId)
      }
    }
    
    return {existingRoom,existingEvent}
  };  

  useEffect(() => {
    socket.auth = { ...user, authHeader };
    socket.connect();
    const updateSocketStatus = (status) => {
      setSocketStatus(status);
    };
    const handleConversationMessage = async (response,notify) => {
      let handlerResponse = await conversationMessageHandler(response);
      if(notify){
        if (response.documentId!==selectedConversation && !handlerResponse?.existingEvent) {
          audio.currentTime = 0;
          audio.play().catch(error => {
            console.error('Audio playback failed:', error);
          });
        }
      }
      handleNotifications(response?.notification)
    };
    socket.on("connect", () => {
      updateSocketStatus("connected");
    });
    socket.on("reconnect_attempt", () => {
      updateSocketStatus("reconnecting");
    });
    socket.on("connect_error", () => {
      updateSocketStatus("connection_error");
    });
    socket.on("disconnect", () => {
      updateSocketStatus("disconnected");
    });
    socket.on("conversation.agent.message.ack",(e)=>{console.log("conversation.agent.message.ack");handleConversationMessage(e,false)});
    socket.on("conversation.message", (e)=>{console.log("conversation.message");handleConversationMessage(e,true)});
    socket.on("conversation.human.assistance",(e)=>{console.log("conversation.human.assistance");handleConversationMessage(e,true)});
    socket.on("conversation.contact.created",(e)=>{console.log("conversation.contact.created");handleConversationMessage(e,false)});
    socket.connected ? updateSocketStatus("connected") : updateSocketStatus("disconnected");
    return () => {
      socket.off("connect", updateSocketStatus);
      socket.off("reconnect_attempt", updateSocketStatus);
      socket.off("connect_error", updateSocketStatus);
      socket.off("disconnect", updateSocketStatus);
      socket.off("conversation.agent.message.ack", updateSocketStatus);
      socket.on("conversation.message", updateSocketStatus);
      socket.off("conversation.human.assistance", updateSocketStatus);
    };
  }, [socket, authUser]);


  async function fetchRoomsData(startAfterDocument=null) {
    try {
      const response = await getRoomsFromServer(null,startAfterDocument);
      if (response.status === 200) {
        if(response.data){
            if(response.data.rooms.length>0){
              const newData = response.data.rooms || [];
              await updateRooms(newData)
            }
        }
      }
    } 
    catch (error) {
      console.error("Error fetching rooms data:", error);
    }
  }


  const filteredConversations = filterConversations(rooms);


  const endOfActiveRef = useRef(null);
  const endOfClosedRef = useRef(null);

  const mobileNavigations = [
    {
      value:"rooms",
      icon: <QuestionAnswerOutlinedIcon/>,
      title: "Conversations",
    },
    // {
    //   value:'notifications',
    //   icon: <NotificationsNoneOutlinedIcon />,
    //   title: "Notifications",
    // },
    // {
    //   value:'profile',
    //   icon: <PermIdentityOutlinedIcon/>,
    //   title: "Profile",
    // },
  ];


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && roomsCount!==rooms.length) {
          const handleFetch = async () => {
            try {
              await fetchRoomsData(rooms[rooms.length - 1].documentId);
            } catch (error) {
              console.error('Error fetching rooms data:', error);
            }
          };

          handleFetch(); 
        }
      },
      { threshold: 1.0 }
    );

    if (endOfActiveRef.current) {
      observer.observe(endOfActiveRef.current);
    }

    if (endOfClosedRef.current) {
      observer.observe(endOfClosedRef.current);
    }

    return () => {
      if (endOfActiveRef.current) {
        observer.unobserve(endOfActiveRef.current);
      }

      if (endOfClosedRef.current) {
        observer.unobserve(endOfClosedRef.current);
      }
    };
  }, [rooms, fetchRoomsData]);

  useEffect(()=>{
    if(selectedConversation!==null){
      deleteNotificationByRoomId(selectedConversation)
    }
  },[selectedConversation])
  
  const [fcmToken,setFCMToken]=useState('')
  const [audio] = useState(new Audio(notificationSound));

  function handleNotifications(data) {
    if (!data || !data.roomId) return;
    if (data.roomId === selectedConversation) {
      deleteNotificationByRoomId(data.roomId);
    } 
    else {
      addNotification(data);
    }
  }
  

  onMessage(messaging, (payload) => {
    const {data}=payload
    const {documentId}=data
    let handlerResponse = conversationMessageHandler({...data,conversation:JSON.parse(data.conversation),event:JSON.parse(data.event)})
    if (Notification.permission === 'granted' && !handlerResponse?.existingEvent) {
      new Notification(payload.notification.title, { 
        body: payload.notification.body, 
        icon: sharedInboxIcon 
      });
      if(documentId!==selectedConversation){
        audio.currentTime = 0;
        audio.play().catch(error => {
          console.error('Audio playback failed:', error);
        });
      }
    }
    handleNotifications(JSON.parse(data?.notification))
  });



  useEffect(() => {
    const unlockAudio = () => {
      audio.play().catch(() => {});
      audio.pause()
      document.removeEventListener('click', unlockAudio);
    };

    document.addEventListener('click', unlockAudio);

    navigator.serviceWorker.addEventListener('message', async(event) => {
      if (event.data && event.data.type === 'PLAY_SOUND' && Notification.permission === 'granted'){
        audio.currentTime = 0;
        audio.play().catch(error => {
          console.error('Audio playback failed:', error);
        });
      }
      if (event.data && event.data.type === 'NEW_BACKGROUND_MESSAGE') {
        const data=event.data.data
        const {documentId}=data
        let handlerResponse = await conversationMessageHandler({...data,conversation:JSON.parse(data.conversation),event:JSON.parse(data.event)})
        if (Notification.permission === 'granted' && !handlerResponse?.existingEvent) {
          if(documentId!==selectedConversation){
            audio.currentTime = 0;
            audio.play().catch(error => {
              console.error('Audio playback failed:', error);
            });
          }
        }
        handleNotifications(JSON.parse(data?.notification))
      }
    });

    
    // Register service worker only if not already registered
    navigator.serviceWorker.register(`${SERVER_BASE_URL}/data/firebase-messaging-sw.js`)
      .then((registration) => {
        console.log('Service Worker registered');
        // Request notification permission
        Notification.requestPermission()
          .then((permission) => {
            if (permission === 'granted') {
              console.log('Notification permission granted.');
              getToken(messaging, { vapidKey: FIREBASE_MESSAGING_VAPID_KEY, serviceWorkerRegistration: registration })
                .then(async (currentToken) => {
                  if (currentToken) {
                    setFCMToken(currentToken)
                    await subscribeToFCMTopic({ fcmToken: currentToken });
                  } else {
                    console.log('No registration token available. Request permission to generate one.');
                  }
                })
                .catch((err) => {
                  console.log('An error occurred while retrieving token. ', err);
                });
            } else {
              console.log('Unable to get permission to notify.');
            }
          });
      })
      .catch((err) => {
        console.log('Service Worker registration failed:', err);
      });
  }, []);
   
  useEffect(()=>{
    if(rooms && rooms.length>0 && paramConversationId!==null){
      setSelectedConversation(paramConversationId)
      setSearchParams({})
    }
  },[rooms,paramConversationId])
  
  return (
    <React.Fragment>
      {!loadingContext && availableChannels.length > 0 ? (
        <Grid
          container
          direction="row"
          spacing={0}
          sx={{
            flex: "auto",
            mt: `${showHeader ? headerHeight : 0}px`,
            mb: `${showFooter ? footerHeight : 0}px`,
          }}
        >
          {selectedWindow==="rooms" && (!isMobile || selectedConversation===null) &&
            <Grid
              item
              container
              direction="column"
              xs={12}
              sm={12}
              md={4}
              lg={3}
              xl={3}
              sx={{ 
                borderRight: "1px solid #F2F2F2" 
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  position: "sticky",
                  top: 0,
                  borderTop: "1px solid #F2F2F2",
                  borderBottom: "1px solid #F2F2F2",
                }}
              >
                <Grid container direction="column" rowSpacing={2} sx={{ p: 2 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <NotificationBar/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <NavigationSearchFilter searchFilter={searchFilter} />
                  </Grid>
                </Grid>
              </Box>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                textColor="secondary"
                indicatorColor="secondary"
                variant="fullWidth"
              >
                <Tab label="Active" {...a11yProps(0)} />
                <Tab label="Closed" {...a11yProps(1)} />
              </Tabs>

              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  overflow: "auto",
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    overflowY: "auto",
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  {rooms.length > 0 ? (
                    <React.Fragment>
                      <TabPanel value={tab} index={0}>
                        {filteredConversations.filter(
                          (conversation) => conversation.status === "active"
                        ).length > 0 ? (
                          <Grid container direction="column">
                            {filteredConversations
                              .filter(
                                (conversation) => conversation.status === "active"
                              )
                              .map((conversation, index) => (
                                  <ConversationCard
                                      key={index}
                                      conversation={conversation}
                                      selected={
                                        selectedConversation === conversation.documentId
                                      }
                                      setSelectedConversation={setSelectedConversation}
                                  />
                              ))}
                              <div ref={endOfActiveRef}>
                                {roomsCount!==rooms.length && 
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      height: "100px",
                                    }}
                                  >
                                    <CircularProgress variant="indeterminate" size={25} color='secondary' />
                                  </Box>
                                }
                              </div>
                          </Grid>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <Typography>No Active Conversations</Typography>
                          </Box>
                        )}
                      </TabPanel>
                      <TabPanel value={tab} index={1}>
                        {filteredConversations.filter(
                          (conversation) => conversation.status === "closed"
                        ).length > 0 ? (
                          <Grid container direction="column">
                            {filteredConversations
                              .filter(
                                (conversation) => conversation.status === "closed"
                              )
                              .map((conversation, index) => (
                                <ConversationCard
                                  key={index}
                                  conversation={conversation}
                                  selected={
                                    selectedConversation === conversation.documentId
                                  }
                                  setSelectedConversation={setSelectedConversation}
                                />
                              ))}
                              <div
                                ref={endOfClosedRef}
                              >
                                {roomsCount!==rooms.length && 
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      height: "100px",
                                    }}
                                  >
                                    <CircularProgress variant="indeterminate" size={25} color='secondary' />
                                  </Box>
                                }   
                              </div>
                          </Grid>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <Typography>No Closed Conversations</Typography>
                          </Box>
                        )}
                      </TabPanel>
                    </React.Fragment>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Typography>No Conversations</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              {!isMobile && appView === "tab" ? (
                  <Box
                    sx={{
                      mt: "auto",
                      position: "sticky",
                      top: 0,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LogoutButton fcmToken={fcmToken} />
                  </Box>
                ) : null
              }
              {isMobile && selectedConversation===null && (
              <Box
                sx={{
                  mt: "auto",
                      position: "sticky",
                  pt: 1,
                  pb: 1,
                  display: "flex",
                  bottom: 0,
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  borderRadius: "30px 30px 0px 0px",
                  width: "100%",
                  backgroundColor: "#F3F4F7",
                }}
              >
                {mobileNavigations.map((navItem) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      onClick={() => setSelectedWindow(navItem.value)}
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      sx={{ color: selectedWindow === navItem.value && "#DF6437" }}
                    >
                      {navItem.icon}
                    </IconButton>
                    <Typography
                      sx={{
                        fontSize:'10px',
                        //fontSize:"calc(8px + (14 - 8) * ((100vw - 400px) / (800 - 300)));",
                        color:selectedWindow === navItem.value ? "#DF6437" : "#848396",
                      }}
                    >
                      {navItem.title}
                    </Typography>
                  </Box>
                ))}
              </Box>
          )}
            </Grid>
          }

          {selectedConversation!==null ?
            <Grid item container xs={12} sm={12} md={8} lg={9} xl={9}>
              {loadingContext ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Typography>Loading</Typography>
                </Box>
              ) : (
                <ConversationWindow
                  selectedConversation={selectedConversation}
                  setSelectedConversation={setSelectedConversation}
                />
              )}


            </Grid>
            :
            <React.Fragment>
              {!isMobile &&
              <Grid item container xs={12} sm={12} md={8} lg={9} xl={9}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography>No Conversation Selected</Typography>
                </Box>
              </Grid>
              }
            </React.Fragment>
          }
        </Grid>
        ):(
         <React.Fragment>
            {!loadingContext ?
              (
                <Dialog open={true} maxWidth="sm" fullWidth>
                  <DialogTitle>No Available Channels</DialogTitle>
                  <DialogContent>
                    
                    {appView==="iframe" ? 
                        <Typography>
                          {`Please navigate to channels to onboard SMS (or) Whatsapp.`}
                        </Typography>
                        :
                        <Typography>
                          Please onboard SMS or WhatsApp to use Conversations.
                        </Typography>
                    }
                  </DialogContent>
                  <DialogActions>
                    {
                      appView==="tab" && 
                        <React.Fragment>
                          <Button
                            disableRipple
                            color='secondary'
                            variant='contained'
                            onClick={()=>{window.open(`${process.env.REACT_APP_APP_BASE_URL}/client/dashboard/sms`)}}
                          >
                            Connect SMS
                          </Button>
                          <Button
                            color='secondary'
                            variant='contained'
                            onClick={()=>{window.open(`${process.env.REACT_APP_APP_BASE_URL}/client/dashboard/whatsapp`)}}
                          >
                            Connect Whatsapp
                          </Button>
                        </React.Fragment>
                    }
                  </DialogActions>
                </Dialog>
              ):(
                <Dialog open={true} maxWidth="sm" fullWidth>
                  <DialogContent sx={{height:'20vh',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                    <CircularProgress color='secondary' size={60}/>
                  </DialogContent>
                </Dialog>
              )
            }

         </React.Fragment>
        )}
    </React.Fragment>
  );
}

export default AppBody


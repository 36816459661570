import React from 'react'
import {Grid,Box, Typography,Avatar } from '@mui/material'
import ReactMarkdown from 'react-markdown';
import { formatDate } from '../../utils/dateTimeFunctions';
import { stringAvatar } from '../../utils/stringFunctions';
import LocationMessage from './LocationMessage';
import ContactsMessage from './ContactsMessage';
import MediaMessage from './MediaMessage';
import ShowMessageType from './ShowMessageType';

const textFormattableEventTypes=['text','reaction','location','contacts','button']
const mediaFormatEventTypes=['image','sticker','video','audio','document']


function CustomerMessage({index,event,variables}) {
    const imageHref = (src) => {
        window.open(src, '_blank');
    }
    const components = {
        img: ({ node, ...props }) => (
            <></>
        ),
        ol: ({ node, ...props }) => (
            <ol style={{ fontSize: '13px', maxWidth: '100%',padding:'10px',marginTop:'10px'}}> 
            {props.children}
            </ol>
        ),
        li: ({ node, ...props }) => (
            <li sx={{ fontSize: '13px', maxWidth: '100%'}}>
               {props.children}
            </li>
        ),
        a: ({ node, ...props }) => (
            <a href={props.href} onClick={(e)=>{e.preventDefault();imageHref(props.href)}} target="_blank" rel="noopener noreferrer">
              {props.children}
            </a>
        ),
        p:({ node, ...props }) => (
            <Typography sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }} >
               {props.children}
            </Typography>
        ),
    };
    return (
        <Grid item key={index} xs={12} sm={12} md={12} lg={12} xl={12}
            aria-label="customerMessage"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                mt:1.5,mb:1.5,
            }}
        >
            <Box sx={{display:'flex',flexDirection:'column',mr:1.5,ml:1,mt:0.5}}>
                <Avatar {...stringAvatar( ((variables && variables.customer && variables.customer.firstName ? variables.customer.firstName + ' ' : '') +(variables && variables.customer && variables.customer.lastName ? variables.customer.lastName : '')) || "Customer")} 
                />
            </Box>
            <Box
                sx={{
                    width: 'fit-content',
                    maxWidth: '60%',
                    overflowWrap: 'break-word',
                    alignItems: 'center',
                }}
            >
                <Box sx={{display:'inline-flex',justifyContent:'flex-end',alignItems:'center',gap:1}}>
                    <Typography 
                        sx={{
                            fontFamily:'DM Sans Medium',
                            fontSize:'15px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        { 
                            ((variables && variables.customer && variables.customer.firstName ? variables.customer.firstName + ' ' : '') +
                            (variables && variables.customer && variables.customer.lastName ? variables.customer.lastName : '')) || "Customer"
                        }
                    </Typography>
                    <Typography 
                        sx={{
                            fontFamily:'DM Sans Light',
                            fontSize:'12px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {formatDate(event.timestamp)}
                    </Typography>
                </Box>
                <Box sx={{mt:0.5}}>
                    {textFormattableEventTypes.includes(event.type) && (
                        typeof event[event.type] === 'string' ? (
                            <ReactMarkdown components={components}>{event[event.type]}</ReactMarkdown>
                        ) 
                        : typeof(event[event.type]) === 'object' && event.type ==='reaction' ? (
                            <ReactMarkdown components={components}>{event[event.type]}</ReactMarkdown>
                        )
                        : typeof(event[event.type]) === 'object' && event.type ==='button' ? (
                           <React.Fragment>
                                <ShowMessageType event={event}/>
                                <ReactMarkdown components={components}>{event[event.type]?.text}</ReactMarkdown>
                           </React.Fragment>
                        )
                        : typeof(event[event.type]) === 'object' && event.type ==='location' ? (
                            <React.Fragment>
                                <ShowMessageType event={event}/>
                                <LocationMessage event={event}/>
                            </React.Fragment>
                        )
                        : typeof(event[event.type]) === 'object' && event.type ==='contacts' ? (
                            <React.Fragment>
                                <ShowMessageType event={event}/>
                                <ContactsMessage event={event}/>
                            </React.Fragment>
                        ) 
                        : null
                    )}
                    {mediaFormatEventTypes.includes(event.type) && (
                        <React.Fragment>
                            <ShowMessageType event={event}/>
                            <MediaMessage event={event} background={'#FFF6ED'}/>
                        </React.Fragment>
                    )}
                </Box>

            </Box>
        </Grid>
  )
}

export default CustomerMessage
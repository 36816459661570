export function hasArrayChanged(oldArray, newArray) {
  if (oldArray.length !== newArray.length) {
      return true;
  }
  return !(oldArray.every(element => newArray.includes(element)) && newArray.every(element => oldArray.includes(element)));
}

export function checkArrayItemsPresence(oldArray, newArray) {
  const result = {};
  oldArray.forEach(item => {
    result[item] = newArray.includes(item);
  });
  newArray.forEach(item => {
    if (!(item in result)) {
      result[item] = true;
    }
  });
  return result;
}

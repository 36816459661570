import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit';
import RequireAuth from '@auth-kit/react-router/RequireAuth'
import LoginPage from "./Components/Pages/LoginPage";
import AppFrame from "./AppFrame";
import AuthRedirect from "./Components/Pages/AuthRedirect";

const store = createStore({
    authName:'_auth',
    authType:'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === "https:",
});

function App(){ 
    return (
        <AuthProvider store={store}>
            <BrowserRouter basename="inbox">
                <Routes>
                    <Route path="/" element={
                        <RequireAuth fallbackPath="/login">
                            <AppFrame/>
                        </RequireAuth>
                    }/> 
                    <Route path="login">
                        <Route index={true} element={<LoginPage />} />
                    </Route>
                    <Route path="authRedirect">
                        <Route index={true} element={<AuthRedirect />} />
                    </Route>
                    <Route path="conversation" element={
                        <RequireAuth fallbackPath="/login">
                            <AppFrame/>
                        </RequireAuth>
                    }/>         
                </Routes>
            </BrowserRouter>
        </AuthProvider>  
    );
}

export default App;
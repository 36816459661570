import { format, isToday, isYesterday } from 'date-fns';

export function convertISOToTimestamp(isoTimeString) {
    const timestamp = Date.parse(isoTimeString);
    return timestamp;
}

export function convertUTCToTimestamp(utcTimeString) {
    const timestamp = Date.parse(utcTimeString);
    return timestamp;
}

export function convertTimeDifferenceToSentence(timestamp) {
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - timestamp;
    
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
        return `${years} year${years > 1 ? 's' : ''} ago`;
    } else if (months > 0) {
        return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (weeks > 0) {
        return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
    } else if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
        return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    }
}


export function formatDate(timestamp){
    const eventDate = new Date(timestamp);
    const currentDate = new Date();

    const isToday = eventDate.getDate() === currentDate.getDate() &&
                    eventDate.getMonth() === currentDate.getMonth() &&
                    eventDate.getFullYear() === currentDate.getFullYear();

    const formattedDateTime = isToday ?
        eventDate.toLocaleString(undefined, {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true 
        }) :
        eventDate.toLocaleString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true 
        });

    return formattedDateTime;
}


export function formatDay(date){
    if (isToday(date)) {
        return 'Today';
    } else if (isYesterday(date)) {
        return 'Yesterday';
    } else {
        return format(date, 'PPP');
    }
};
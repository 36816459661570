import { Card, CardMedia,CardContent,Typography, CardActions, Button} from "@mui/material";

export const ProductCard = ({data,color}) => {
    return (
      <Card variant="outlined" sx={{borderRadius:'8px',width:'100%'}}>
        {/* {console.log(data)} */}
        <CardMedia
             component="img"
            height="150"
            sx={{ objectFit:'scale-down' }}
            image={data.mediaUrl}
        />
        <CardContent sx={{p:1,height:'40px'}}>
            <Typography variant='caption' sx={{ fontSize: '14px',fontWeight:600, display: 'block'}} >
            {data.name}
            </Typography>
           {/*  <Typography variant='caption' sx={{ fontSize: '12px', display: 'block'}}>
            {data.price}
            </Typography> */}
        </CardContent>
        
          <CardActions /* sx={{justifyContent:"right"}} */>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                window.open(data.productUrl, '_blank');
              }}
              sx={{
                textTransform: 'none',
                background: color,
                maxHeight: '25px',
                fontSize: '10px',
                boxShadow: 'none',
                '&:hover': {
                  background: color,
                },
              }}
              disableFocusRipple
              disableRipple
              disableTouchRipple
            >
              Show
            </Button>
          </CardActions> 
      </Card>
    );
  };